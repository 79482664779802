import { Component, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-pre-racco',
  standalone: true,
  imports: [FullCalendarModule, LoaderComponent, CommonModule, NgIconsModule],
  templateUrl: './pre-racco.component.html',
  styleUrl: './pre-racco.component.scss',
})
export class PreRaccoComponent {
  events: any = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
    events: this.events,
    locale: frLocale,
    datesSet: (info) => {
      let month = new Date(
        (info.start.getTime() + info.end.getTime()) / 2
      ).getMonth();
      // get the month index
      this.fetchMonth(month);
    },

    dateClick: (info) => {
      let date = info.dateStr;
      // date is a string in the format "YYYY-MM-DD", format it to "YYYY/MM/DD"
      date = date.split('-').join('/');
      this.loadingDay = true;
      this.fetchDay(date);
    },
    headerToolbar: {
      start: 'title',
      center: '',
      end: 'prev,next',
    },
  };

  filtres: any;
  platform: string = 'losange';
  loading: boolean = true;
  loadingDay: boolean = false;

  resNumbers: any;
  lastRes: any;
  monthInfos: any;

  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChildren('tableauRef') tableauRef!: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtres = this.apiService.filtres;
    this.platform = this.apiService.platform;
    const today = new Date();
    const month = today.getMonth();

    if (this.apiService.filtres.lot !== null) {
      this.fetchData(month);
    }
    this.apiService.filtresChange
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((filtres: any) => {
        this.filtres = filtres;
        if (this.filtres.onglet !== '6') return;
        this.loading = true;

        this.fetchData(month);
      });
  }

  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        // child.addEventListener('click', () => {
        //   this.openModal(
        //     child.id,
        //     array,
        //     null,
        //     child.querySelector('.text')?.textContent
        //   );
        // });
      });
    });

    this.cd.detectChanges();
  }

  fetchData(month: any) {
    combineLatest([
      this.apiService.getResNumbers(this.filtres.lot),
      this.apiService.getLastRes(this.filtres.lot),
      this.apiService.getMonthsInfos(this.filtres.lot, (month + 1).toString()),
    ]).subscribe(([resNumbers, lastRes, monthInfos]) => {
      this.resNumbers = resNumbers;
      this.lastRes = lastRes;
      // this.lastRes.data.forEach((day: any) => {
      //   this.lastRes.data.push(day);
      // });
      this.monthInfos = monthInfos;
      this.fillMonth(monthInfos);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      alert('Erreur lors du chargement des données');
    });
  }

  fetchMonth(month: any) {
    this.apiService
      .getMonthsInfos(this.filtres.lot, (month + 1).toString())
      .subscribe((monthInfos) => {
        this.fillMonth(monthInfos);
      });
  }

  fillMonth(month: any) {
    // for each object in month,
    this.events = [];
    month.forEach((day: any) => {
      const date = day.date;

      // day['type 1'].forEach((type: any) => {
      //   // if (!type.use) return;
      //   this.events.push({
      //     title: `${type.use} rendez-vous le matin`,
      //     start: `${date}T08:00:00`, // Heure du matin
      //     end: `${date}T12:00:00`, // Heure du midi
      //     color: 'transparent',
      //     textColor: 'red',
      //     display: 'block',
      //     allDay: true,
      //   });
      // });

      // // Après-midi (type 2)
      // day['type 2'].forEach((type: any) => {
      //   // if (!type.use) return;
      //   this.events.push({
      //     title: `${type.use} rendez-vous l'après-midi`,
      //     start: `${date}T14:00:00`, // Heure de l'après-midi
      //     end: `${date}T18:00:00`, // Heure du soir
      //     color: 'transparent',
      //     textColor: 'red',
      //     display: 'block',
      //     allDay: true,
      //   });
      // });

      let nbUsed = day['type 1'][0].use + day['type 2'][0].use;

      this.events.push({
        title: `${nbUsed} rendez-vous`,
        start: `${date}T08:00:00`, // Heure du matin
        end: `${date}T18:00:00`, // Heure du soir
        color: '#ff7e75',
        textColor: 'white',
        display: 'block',
        defaultAllDay: true,
        allDay: true,
      });

      let nbAvailable = day['type 1'][0].available + day['type 2'][0].available;
      this.events.push({
        title: `${nbAvailable} dispo${nbAvailable > 1 ? 's' : ''}`,
        start: `${date}T08:00:00`, // Heure du matin
        end: `${date}T18:00:00`, // Heure du soir
        color: '#72C65D',
        textColor: 'white',
        display: 'block',
        defaultAllDay: true,
        allDay: true,
      });
    });

    // // update the calendar so it displays the new events
    this.calendarOptions.events = this.events;
    // this.cd.detectChanges();
  }

  dayInfos: any;

  fetchDay(day: any) {
    this.apiService.getDayInfos(this.filtres.lot, day).subscribe((dayInfos) => {
      this.dayInfos = dayInfos;
      if (this.dayInfos.length) {

        // formate this.dayInfos[0].date (qui est au format "YYYY-MM-DD") en "DD mois YYYY"
        this.dayInfos[0].date = new Date(this.dayInfos[0].date).toLocaleDateString('fr-FR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
      }
      this.loadingDay = false;
    });
  }

  closeModal() {
    this.dayInfos = null;
  }

  goToForm() {
    window.open(`https://www.${this.platform}-fibre.fr/espace-pre-raccordement/`, '_blank');
  }

  goToVDR() {
    window.open(`https://vdr.fibre.services/preraccos`, '_blank');
  }
}
