import { CommonModule } from '@angular/common';
import { Component, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import * as L from 'leaflet';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { FormsModule } from '@angular/forms';
import { NgIconComponent } from '@ng-icons/core';
import { Router, ActivatedRoute } from '@angular/router';
import { vectorBasemapLayer } from 'esri-leaflet-vector';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { environment } from '../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgIconComponent,
    AutocompleteLibModule,
  ],
})
export class MapComponent implements AfterViewInit {
  private map: any;
  loading = false;
  params: any;
  filters: any;
  prises: any;
  prisesFiltered: any;
  arcgis_key = environment.arcgis_key;
  isSatellite = true;

  coords = {
    min_lat: 47.66151416424682,
    max_lat: 47.69971694355844,
    min_lon: 7.19381259918213,
    max_lon: 7.238810787200928,
  };

  preLoadedCoords: any;
  preLoadedBounds: any;
  preLoadedIMB: any;
  preLoadedEcartsAcces: any;

  mapZoom = 16;

  cities: any = [];
  searchCommune: string = '';

  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe((params: any) => {
      const paramUrl = params.params;
      if (paramUrl.lat && paramUrl.lon) {
        this.preLoadedCoords = {
          lat: paramUrl.lat,
          lon: paramUrl.lon,
        };
      }
      if (
        paramUrl.min_lat &&
        paramUrl.max_lat &&
        paramUrl.min_lon &&
        paramUrl.max_lon
      ) {
        this.preLoadedBounds = {
          min_lat: paramUrl.min_lat,
          max_lat: paramUrl.max_lat,
          min_lon: paramUrl.min_lon,
          max_lon: paramUrl.max_lon,
        };
      }

      if (paramUrl.imb) {
        this.preLoadedIMB = paramUrl.imb;
      }

      if (paramUrl.ecarts) {
        this.preLoadedEcartsAcces = paramUrl.ecarts;
      }
    });

    if (!this.apiService.parameters) {
      this.getParams();
    } else {
      this.params = this.apiService.parameters;
      this.createFilters(this.params);
      this.initMap();
    }

    this.cities = this.apiService.cities;
    if (this.cities) {
      this.calculateCenter();
    }
    this.apiService.citiesChange.subscribe((cities: any) => {
      this.cities = cities;
      this.calculateCenter();
    });
  }

  calculateCenter() {
    this.cities.forEach((city: any) => {
      city.max_latitude = parseFloat(city.max_latitude);
      city.min_latitude = parseFloat(city.min_latitude);
      city.max_longitude = parseFloat(city.max_longitude);
      city.min_longitude = parseFloat(city.min_longitude);

      let lat: any = (city.max_latitude + city.min_latitude) / 2;
      let lon: any = (city.max_longitude + city.min_longitude) / 2;

      city.center = {
        lat: lat,
        lon: lon,
      };
    });
  }

  getParams() {
    this.apiService.getParameters().subscribe((res: any) => {
      if (res.success) {
        this.params = res.data;
        this.params.motifs_blocage_cible = this.params.motifs;
        this.params.motifs_blocage_encours = this.params.motifs;
        let copy = JSON.parse(JSON.stringify(this.params));
        this.createFilters(copy);
        this.initMap();
      }
    });
  }

  createFilters(params: any) {
    this.params.motifs_blocage_cible = this.params.motifs;
    this.params.motifs_blocage_encours = this.params.motifs;
    this.filters = {
      deploye: params.deploye,
      pto: params.pto,
      rad: params.rad,
      motifs_blocage_cible: params.motifs_blocage_cible,
      motifs_blocage_encours: params.motifs_blocage_encours,
    };

    // for each items in deploye, pto and rad, add a new key is_selected and set it to true
    this.filters.deploye.forEach((item: any) => {
      item.is_selected = true;
    });
    this.filters.all_deploye = true;

    // for each items in motifs_blocage_cible and motifs_blocage_encours, transform the item into an object with a key is_selected set to true and a key name set to the value of the item
    this.filters.motifs_blocage_cible = this.filters.motifs_blocage_cible.map(
      (item: any) => {
        return { name: item, is_selected: true };
      }
    );
    this.filters.cible_on = true;

    this.filters.motifs_blocage_encours =
      this.filters.motifs_blocage_encours.map((item: any) => {
        return { name: item, is_selected: true };
      });
    this.filters.encours_on = true;

    this.filters.rad.forEach((item: any) => {
      item.is_selected = true;
    });
    this.filters.rad_on = true;

    this.filters.pto.forEach((item: any) => {
      item.is_selected = false;
    });
    this.filters.all_pto = false;

    this.filters.imb = {
      all: true,
      oui: false,
      non: false,
    };

    this.filters.presence_cuivre = {
      all: true,
      oui: false,
      non: false,
    };

    this.filters.ecarts_acces = false;
  }

  getRadStatus(prise: any) {
    let radStatus = [];
    if (prise.go_region === 1) radStatus.push('Go région');
    if (prise.nogo_region === 1) radStatus.push('No Go');
    if (prise.en_attente === 1) radStatus.push('En attente');
    if (prise.prequal === 1) radStatus.push('PREQUAL');
    if (
      prise.go_region === 0 &&
      prise.nogo_region === 0 &&
      prise.en_attente === 0 &&
      prise.prequal === 0 &&
      prise.statut === 'RACCORDABLE DEMANDE'
    ) {
      radStatus.push('Autre');
    }
    return radStatus;
  }

  private initMap(): void {
    this.map = L.map('map', {
      // center: [ 47.19717795172789, 2.2302246093750004 ],
      // center: [ 47.68193004331683, 7.213590035383323 ],
      zoom: 16,
      // zoom: 18
    });

    // this.map.fitBounds([
    //   [this.coords.min_lat, this.coords.min_lon],
    //   [this.coords.max_lat, this.coords.max_lon]
    // ]);

    //set the zoom to 16
    if (this.preLoadedCoords) {
      this.map.setView(
        [this.preLoadedCoords.lat, this.preLoadedCoords.lon],
        19
      );
    } else {
      this.map.setView([47.68193004331683, 7.213590035383323], 16);
    }

    if (this.preLoadedBounds) {
      this.map.fitBounds([
        [this.preLoadedBounds.min_lat, this.preLoadedBounds.min_lon],
        [this.preLoadedBounds.max_lat, this.preLoadedBounds.max_lon],
      ]);
    }

    this.toggleVue();

    const bounds = this.map.getBounds();
    this.coords = {
      min_lat: bounds.getSouth(),
      max_lat: bounds.getNorth(),
      min_lon: bounds.getWest(),
      max_lon: bounds.getEast(),
    };

    if (this.map.getZoom() >= 16) {
      this.loading = true;
      this.getPrises(this.coords);
    }

    // when the user starts moving the map, remove prises markers that are not currently visible
    this.map.on('move', () => {
      this.mapZoom = this.map.getZoom();
      if (this.mapZoom >= 16) {
        // this.map.eachLayer((layer: any) => {
        //   if (layer instanceof L.Marker) {
        //     this.map.removeLayer(layer);
        //   }
        // });
        this.loading = true;
      } else {
        this.map.eachLayer((layer: any) => {
          if (layer instanceof L.Marker) {
            this.map.removeLayer(layer);
          }
        });
        let icon = new L.Icon({
          iconUrl: 'assets/marker-blue.png',
          iconSize: [30, 30],
        });

        let icons = [
          'assets/marker-blue.png',
          'assets/marker-green.png',
          'assets/marker-orange.png',
          'assets/marker-red.png',
        ];

        this.cities.forEach((city: any) => {
          // L.marker([city.center.lat, city.center.lon], {
          //   icon: new L.Icon({
          //     iconUrl: icons[parseInt(city.lot) - 1],
          //     iconSize: [30, 30],
          //   }),
          // }).addTo(this.map);

          let marker = L.marker([city.center.lat, city.center.lon], {
            icon: new L.Icon({
              iconUrl: icons[parseInt(city.lot) - 1],
              iconSize: [30, 30],
            }),
          })

          marker.bindPopup(city.name);

          marker.addTo(this.map);
        });
      }
      this.map.eachLayer((layer: any) => {
        // if (layer instanceof L.Marker) {
        if (layer instanceof L.Marker) {
          let loadingZone = this.map.getBounds();

          let i = 0.05;
          loadingZone._northEast.lat += i;
          loadingZone._northEast.lng += i;
          loadingZone._southWest.lat -= i;
          loadingZone._southWest.lng -= i;

          if (!loadingZone.contains(layer.getLatLng())) {
            this.map.removeLayer(layer);
          }
        }
      });
    });

    this.map.on('moveend', () => {
      const bounds = this.map.getBounds();
      this.coords = {
        min_lat: bounds.getSouth(),
        max_lat: bounds.getNorth(),
        min_lon: bounds.getWest(),
        max_lon: bounds.getEast(),
      };

      let i = 0.01;

      this.coords.min_lat -= i;
      this.coords.max_lat += i;
      this.coords.min_lon -= i;
      this.coords.max_lon += i;

      let zoom = this.map.getZoom();
      if (zoom >= 16) {
        // if (zoom >= 12) {
        this.getPrises(this.coords, true);
      }
    });
  }

  timeOutRef: any;

  prisesOrdered: any = {
    deploye: [],
    motifs_blocage_cible: [],
    motifs_blocage_encours: [],
    rad: [],
    pto: [],
    presence_cuivre: [],
    imb: [],
  };

  getPrises(coords: any, isMove?: boolean) {
    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }

    this.timeOutRef = setTimeout(() => {
      this.apiService.getMap(coords).subscribe((res: any) => {
        this.prises = res.data;

        this.prisesOrdered.deploye.all = this.prises.filter((item: any) => {
          return item.statut === 'DEPLOYE';
          // return item.statut === 'DEPLOYE' && item.a_raccorder === 0 && item['pto actif'] === 0 && item['pto inactif'] === 0;
        });

        this.prisesOrdered.deploye.all_none = this.prises.filter(
          (item: any) => {
            return (
              item.statut === 'DEPLOYE' &&
              item.gel_com === 0 &&
              item.ecart_acces === 0
            );
            // return item.statut === 'DEPLOYE' && item.gel_com === 0 && item.ecart_acces === 0 && item.a_raccorder === 0 && item['pto actif'] === 0 && item['pto inactif'] === 0;
          }
        );

        this.prisesOrdered.deploye.gel_com = this.prises.filter((item: any) => {
          return item.gel_com === 1;
          // return item.gel_com === 1 && item.a_raccorder === 0 && item['pto actif'] === 0 && item['pto inactif'] === 0;
        });

        this.prisesOrdered.deploye.ecart_acces = this.prises.filter(
          (item: any) => {
            return item.ecart_acces === 1;
            // return item.ecart_acces === 1 && item.a_raccorder === 0 && item['pto actif'] === 0 && item['pto inactif'] === 0;
          }
        );

        this.prisesOrdered.motifs_blocage_cible.all = this.prises.filter(
          (item: any) => {
            return item.statut === 'CIBLE';
          }
        );

        this.filters.motifs_blocage_cible.forEach((item: any) => {
          this.prisesOrdered.motifs_blocage_cible[item.name] =
            this.prises.filter((prise: any) => {
              return prise.statut === 'CIBLE' && prise.motif === item.name;
            });
        });

        this.prisesOrdered.motifs_blocage_cible.aucun = this.prises.filter(
          (item: any) => {
            return item.statut === 'CIBLE' && item.motif === null;
          }
        );

        this.prisesOrdered.motifs_blocage_encours.all = this.prises.filter(
          (item: any) => {
            return item.statut === 'EN COURS DE DEPLOIEMENT';
          }
        );

        this.filters.motifs_blocage_encours.forEach((item: any) => {
          this.prisesOrdered.motifs_blocage_encours[item.name] =
            this.prises.filter((prise: any) => {
              return (
                prise.statut === 'EN COURS DE DEPLOIEMENT' &&
                prise.motif === item.name
              );
            });
        });

        this.prisesOrdered.motifs_blocage_encours.aucun = this.prises.filter(
          (item: any) => {
            return (
              item.statut === 'EN COURS DE DEPLOIEMENT' && item.motif === null
            );
          }
        );

        this.prisesOrdered.rad.all = this.prises.filter((item: any) => {
          return item.statut === 'RACCORDABLE DEMANDE';
        });

        this.prisesOrdered.rad.all_none = this.prises.filter((item: any) => {
          return (
            item.statut === 'RACCORDABLE DEMANDE' &&
            item.go_region === 0 &&
            item.nogo_region === 0 &&
            item.en_attente === 0 &&
            item.prequal === 0
          );
        });

        this.filters.rad.forEach((item: any) => {
          this.prisesOrdered.rad[item.name] = this.prises.filter(
            (prise: any) => {
              return (
                prise.statut === 'RACCORDABLE DEMANDE' &&
                this.getRadStatus(prise).includes(item.name)
              );
            }
          );
        });

        this.prisesOrdered.presence_cuivre.oui = this.prises.filter(
          (item: any) => {
            return item.categorie_orange != '666';
          }
        );

        this.prisesOrdered.presence_cuivre.non = this.prises.filter(
          (item: any) => {
            return item.categorie_orange === '666';
          }
        );

        this.prisesOrdered.pto.actif = this.prises.filter((item: any) => {
          return item['pto actif'] >= 1;
        });

        this.prisesOrdered.pto.inactif = this.prises.filter((item: any) => {
          return item['pto inactif'] >= 1;
        });

        this.prisesOrdered.pto.a_raccorder = this.prises.filter((item: any) => {
          return item.a_raccorder >= 1;
        });

        this.prisesOrdered.imb.oui = this.prises.filter((item: any) => {
          return item.imb !== null;
        });

        // in this.prisesOrdered.imb.non, put the prises that do not have the key imb
        this.prisesOrdered.imb.non = this.prises.filter((item: any) => {
          return item.imb === undefined;
        });

        this.prisesOrdered.presence_cuivre.oui = this.prises.filter(
          (item: any) => {
            return item.categorie_orange != '666';
          }
        );

        this.prisesOrdered.presence_cuivre.non = this.prises.filter(
          (item: any) => {
            return item.categorie_orange === '666';
          }
        );

        this.prisesOrdered.ecarts_acces = this.prises.filter((item: any) => {
          return item.ecart_acces < -2;
        });

        // keeping in mind the current filters, build the corresponding prises

        this.loading = false;

        if (this.preLoadedEcartsAcces) {
          this.filters.ecarts_acces = true;
          this.buildPrises(this.prisesOrdered.ecarts_acces, isMove);
          return;
        }

        this.filterPrisesOnMapMove(isMove);

        if (this.preLoadedIMB) {
          this.changeIMB(this.preLoadedIMB);
        }
      });
    }, 100);
  }

  filterPrisesOnMapMove(isMove?: boolean) {
    let toShowPrises: any[] = [];

    if (!isMove) this.preLoadedEcartsAcces = null;

    if (this.filters.all_deploye) {
      // toShowPrises = toShowPrises.concat(this.prisesOrdered.deploye.all_none);
      toShowPrises = toShowPrises.concat(this.prisesOrdered.deploye.all);
    }

    if (this.filters.deploye[0].is_selected) {
      toShowPrises = toShowPrises.concat(this.prisesOrdered.deploye.gel_com);
    }

    if (this.filters.deploye[1].is_selected) {
      toShowPrises = toShowPrises.concat(
        this.prisesOrdered.deploye.ecart_acces
      );
    }

    if (this.filters.all_pto) {
      toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.actif);
      toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.inactif);
      toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.a_raccorder);
    } else {
      if (this.filters.pto[0].is_selected) {
        toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.actif);
      }
      if (this.filters.pto[1].is_selected) {
        toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.inactif);
      }
      if (this.filters.pto[2].is_selected) {
        toShowPrises = toShowPrises.concat(this.prisesOrdered.pto.a_raccorder);
      }
    }

    this.filters.motifs_blocage_cible.forEach((item: any) => {
      if (item.is_selected) {
        toShowPrises = toShowPrises.concat(
          this.prisesOrdered.motifs_blocage_cible[item.name]
        );
      }
    });

    if (this.filters.cible_on) {
      toShowPrises = toShowPrises.concat(
        this.prisesOrdered.motifs_blocage_cible.aucun
      );
    }

    this.filters.motifs_blocage_encours.forEach((item: any) => {
      if (item.is_selected) {
        toShowPrises = toShowPrises.concat(
          this.prisesOrdered.motifs_blocage_encours[item.name]
        );
      }
    });

    if (this.filters.encours_on) {
      toShowPrises = toShowPrises.concat(
        this.prisesOrdered.motifs_blocage_encours.aucun
      );
    }

    if (this.filters.rad_on) {
      toShowPrises = toShowPrises.concat(this.prisesOrdered.rad.all_none);
    }

    this.filters.rad.forEach((item: any) => {
      if (item.is_selected) {
        toShowPrises = toShowPrises.concat(this.prisesOrdered.rad[item.name]);
      }
    });

    if (!this.filters.imb.all) {
      if (this.filters.imb.oui) {
        toShowPrises = toShowPrises.filter((item: any) => {
          return item.imb !== null;
        });
      } else if (this.filters.imb.non) {
        toShowPrises = toShowPrises.filter((item: any) => {
          return item.imb === undefined;
        });
        toShowPrises = toShowPrises.concat(this.prisesOrdered.imb.non);
      }
    } else {
      toShowPrises = toShowPrises.concat(this.prisesOrdered.imb.non);
    }

    if (!this.filters.presence_cuivre.all) {
      if (this.filters.presence_cuivre.oui) {
        toShowPrises = toShowPrises.filter((item: any) => {
          return item.categorie_orange != '666';
        });
      } else if (this.filters.presence_cuivre.non) {
        toShowPrises = toShowPrises.filter((item: any) => {
          return item.categorie_orange === '666';
        });
      }
    } else {
      // toShowPrises = toShowPrises.concat(this.prisesOrdered.presence_cuivre.non);
    }

    this.buildPrises(toShowPrises, isMove);
  }

  changeDeploye(id: any, all?: boolean) {
    // if this.filters.all_pto is true, turn it off
    if (this.filters.all_pto) {
      this.filters.all_pto = false;
      this.filters.pto.forEach((item: any) => {
        item.is_selected = false;
      });
      // this.removePrises(this.prisesOrdered.pto.actif);
      // this.removePrises(this.prisesOrdered.pto.inactif);
      // this.removePrises(this.prisesOrdered.pto.a_raccorder);
    }

    if (!all && all !== undefined) {
      // this.removePrises(this.prisesOrdered.deploye.all);
      this.filters.deploye.forEach((item: any) => {
        item.is_selected = false;
      });
      // return;
    } else if (all == true) {
      // this.addPrises(this.prisesOrdered.deploye.all);
      this.filters.deploye.forEach((item: any) => {
        item.is_selected = true;
      });
      // return;
    }

    this.filterPrisesOnMapMove();

    // if (id == 1 && this.filters.deploye[0].is_selected) {
    //   this.addPrises(this.prisesOrdered.deploye.gel_com);
    // } else if (id == 1 && !this.filters.deploye[0].is_selected) {
    //   this.removePrises(this.prisesOrdered.deploye.gel_com);
    // }

    // if (id == 2 && this.filters.deploye[1].is_selected) {
    //   this.addPrises(this.prisesOrdered.deploye.ecart_acces);
    // } else if (id == 2 && !this.filters.deploye[1].is_selected) {
    //   this.removePrises(this.prisesOrdered.deploye.ecart_acces);
    // }
  }

  changeCible(index?: any, name?: any, all?: boolean) {
    if (this.filters.all_pto) {
      this.filters.all_pto = false;
      this.filters.pto.forEach((item: any) => {
        item.is_selected = false;
      });
    }

    if (!all && all !== undefined) {
      this.filters.motifs_blocage_cible.forEach((item: any) => {
        item.is_selected = false;
      });
    } else if (all == true) {
      this.filters.motifs_blocage_cible.forEach((item: any) => {
        item.is_selected = true;
      });
    }

    this.filterPrisesOnMapMove();
  }

  changeEncours(index?: any, name?: any, all?: boolean) {
    if (this.filters.all_pto) {
      this.filters.all_pto = false;
      this.filters.pto.forEach((item: any) => {
        item.is_selected = false;
      });
    }

    if (!all && all !== undefined) {
      this.filters.motifs_blocage_encours.forEach((item: any) => {
        item.is_selected = false;
      });
    } else if (all == true) {
      this.filters.motifs_blocage_encours.forEach((item: any) => {
        item.is_selected = true;
      });
    }

    this.filterPrisesOnMapMove();
  }

  changeRad(id: any, all?: boolean) {
    if (this.filters.all_pto) {
      this.filters.all_pto = false;
      this.filters.pto.forEach((item: any) => {
        item.is_selected = false;
      });
    }

    if (!all && all !== undefined) {
      this.filters.rad.forEach((item: any) => {
        item.is_selected = false;
      });
      // return
    } else if (all == true) {
      this.filters.rad.forEach((item: any) => {
        item.is_selected = true;
      });
    }
    this.filterPrisesOnMapMove();
  }

  changePTO(id: any, all?: boolean) {
    // when one of the filter of pto is on, turn off all the other filters and remove all the prises from the map
    if (!all && all !== undefined) {
      this.filters.all_pto = false;
      // this.removePrises(this.prises)
      this.filters.pto.forEach((item: any) => {
        item.is_selected = false;
      });
      this.toggleAllOtherFilters(true);
      this.filterPrisesOnMapMove();

      return;
    } else if (all == true) {
      this.filters.all_pto = true;
      // this.removePrises(this.prises)
      // this.addPrises(this.prisesOrdered.pto.actif);
      // this.addPrises(this.prisesOrdered.pto.inactif);
      // this.addPrises(this.prisesOrdered.pto.a_raccorder);
      this.filters.pto.forEach((item: any) => {
        item.is_selected = true;
      });
      this.toggleAllOtherFilters(false);
      this.filterPrisesOnMapMove();

      return;
    }

    // this.removePrises(this.prises);

    if (id == 1 && this.filters.pto[0].is_selected) {
      this.addPrises(this.prisesOrdered.pto.actif);
      this.toggleAllOtherFilters(false);
      this.filterPrisesOnMapMove();
    } else if (id == 1 && !this.filters.pto[0].is_selected) {
      this.removePrises(this.prisesOrdered.pto.actif);
    }

    if (id == 2 && this.filters.pto[1].is_selected) {
      this.addPrises(this.prisesOrdered.pto.inactif);
      this.toggleAllOtherFilters(false);
      this.filterPrisesOnMapMove();
    } else if (id == 2 && !this.filters.pto[1].is_selected) {
      this.removePrises(this.prisesOrdered.pto.inactif);
    }

    if (id == 3 && this.filters.pto[2].is_selected) {
      this.addPrises(this.prisesOrdered.pto.a_raccorder);
      this.toggleAllOtherFilters(false);
      this.filterPrisesOnMapMove();
    } else if (id == 3 && !this.filters.pto[2].is_selected) {
      this.removePrises(this.prisesOrdered.pto.a_raccorder);
    }

    // this.toggleAllOtherFilters(false);
  }

  toggleAllOtherFilters(bool: boolean) {
    // if bool is true, turn off all the other filters than pto
    this.filters.all_deploye = bool;
    this.filters.deploye.forEach((item: any) => {
      item.is_selected = bool;
    });
    this.filters.rad_on = bool;
    this.filters.rad.forEach((item: any) => {
      item.is_selected = bool;
    });
    this.filters.cible_on = bool;
    this.filters.motifs_blocage_cible.forEach((item: any) => {
      item.is_selected = bool;
    });
    this.filters.encours_on = bool;
    this.filters.motifs_blocage_encours.forEach((item: any) => {
      item.is_selected = bool;
    });

    if (bool) this.filterPrisesOnMapMove();
  }

  changePresenceCuivre(presence: string) {
    if (presence === 'tous') {
      this.filters.presence_cuivre.all = true;
      this.filters.presence_cuivre.oui = false;
      this.filters.presence_cuivre.non = false;
      // this.addPrises(this.prisesOrdered.presence_cuivre.oui);
      // this.addPrises(this.prisesOrdered.presence_cuivre.non);
      this.filterPrisesOnMapMove();
    } else if (presence === 'oui') {
      this.filters.presence_cuivre.all = false;
      this.filters.presence_cuivre.oui = true;
      this.filters.presence_cuivre.non = false;
      // this.addPrises(this.prisesOrdered.presence_cuivre.oui);
      // this.removePrises(this.prisesOrdered.presence_cuivre.non);
      this.filterPrisesOnMapMove();
    } else if (presence === 'non') {
      this.filters.presence_cuivre.all = false;
      this.filters.presence_cuivre.oui = false;
      this.filters.presence_cuivre.non = true;
      this.filterPrisesOnMapMove();
      // this.addPrises(this.prisesOrdered.presence_cuivre.non);
      // this.removePrises(this.prisesOrdered.presence_cuivre.oui);
    }
  }

  changeIMB(imbToShow: string) {
    if (imbToShow === 'tous') {
      this.filters.imb.all = true;
      this.filters.imb.oui = false;
      this.filters.imb.non = false;

      // this.addPrises(this.prisesOrdered.imb.oui);
      // this.addPrises(this.prisesOrdered.imb.non);
      this.addPrises(this.prisesOrdered.imb.non);

      this.filterPrisesOnMapMove();
    } else if (imbToShow === 'oui') {
      this.filters.imb.all = false;
      this.filters.imb.oui = true;
      this.filters.imb.non = false;
      // this.addPrises(this.prisesOrdered.imb.oui);
      // this.removePrises(this.prisesOrdered.imb.non);
      this.filterPrisesOnMapMove();
    } else if (imbToShow === 'non') {
      this.filters.imb.all = false;
      this.filters.imb.oui = false;
      this.filters.imb.non = true;
      this.filterPrisesOnMapMove();
      // this.removePrises(this.prisesOrdered.imb.oui);
      // this.addPrises(this.prisesOrdered.imb.non);
    }
  }

  removePrises(prises: any) {
    // remove all prises from the map
    prises.forEach((prise: any) => {
      this.map.eachLayer((layer: { options: { imb: any } }) => {
        if (layer instanceof L.Marker && layer.options.imb === prise.imb) {
          this.map.removeLayer(layer);
        }
      });
    });
  }

  icons = {
    1: L.icon({
      iconUrl: 'assets/images/house_grey.png',
      iconSize: [24, 24],
    }),
    2: L.icon({
      iconUrl: 'assets/images/house_orange.png',
      iconSize: [24, 24],
    }),
    3: L.icon({
      iconUrl: 'assets/images/house_blue.png',
      iconSize: [24, 24],
    }),
    4: L.icon({
      iconUrl: 'assets/images/house_green.png',
      iconSize: [24, 24],
    }),
    5: L.icon({
      iconUrl: 'assets/images/house_pink.png',
      iconSize: [24, 24],
    }),
    6: L.icon({
      iconUrl: 'assets/images/house_red.png',
      iconSize: [24, 24],
    }),
    7: L.icon({
      iconUrl: 'assets/images/plug_green.png',
      iconSize: [24, 24],
    }),
    8: L.icon({
      iconUrl: 'assets/images/plug_orange.png',
      iconSize: [24, 24],
    }),
    9: L.icon({
      iconUrl: 'assets/images/plug_pink.png',
      iconSize: [24, 24],
    }),
  };

  addPrises(prises: any) {
    let closestMarker: any;
    let closestDistance = 1000000000000000;
    prises.forEach((prise: any) => {
      let icon = this.icons[4];

      if (prise.source === 'ORANGE') icon = this.icons[6];
      else if (
        this.isPTOVert(prise) &&
        (this.filters.all_pto ||
          this.filters.pto.some((item: any) => item.is_selected))
      )
        icon = this.icons[7];
      else if (
        this.isPTORose(prise) &&
        (this.filters.all_pto ||
          this.filters.pto.some((item: any) => item.is_selected))
      )
        icon = this.icons[9];
      else if (
        this.isPTOOrange(prise) &&
        (this.filters.all_pto ||
          this.filters.pto.some((item: any) => item.is_selected))
      )
        icon = this.icons[8];
      else if (this.isDeploye(prise)) icon = this.icons[4];
      else if (this.isDeploiement(prise)) icon = this.icons[5];
      else if (this.isCible(prise)) icon = this.icons[1];
      else if (this.isRad(prise)) icon = this.icons[2];

      let markerOptions = {
        icon: icon,
        imb: prise.imb,
      };

      let marker = L.marker([prise.lat, prise.lon], markerOptions).addTo(
        this.map
      );

      marker.bindPopup(
        `<b>${prise.imb ?? ''}</b><br>
        ${prise.numero ?? ''} ${prise.voie ?? ''}<br>
        NB prises: ${prise.nb_prise ?? ''}<br>
        Statut: ${prise.statut ?? ''}<br>
        Catégorie: ${prise.categorie_orange ?? ''}<br>
        Motif: ${prise.motif ?? ''}<br>
        REF: ${prise.ref ?? ''}<br>
        VDR: ${prise.vdr ?? ''}<br>
        PTO actif: ${prise['pto actif'] ?? ''}<br>
        PTO inactif: ${prise['pto inactif'] ?? ''}<br>
        PTO à raccorder: ${prise.a_raccorder ?? ''}<br>
        Ecart accès: ${prise.ecart_acces ?? ''}<br>
        Lot: ${prise.lot ?? ''}<br>`
      );

      if (this.preLoadedCoords) {
        let distance = Math.sqrt(
          (prise.lat - this.preLoadedCoords.lat) ** 2 +
            (prise.lon - this.preLoadedCoords.lon) ** 2
        );
        if (distance < closestDistance) {
          closestDistance = distance;
          closestMarker = marker;
        }
      }
    });

    // if the coords of the marker are almost similar to this.preloadedCoords, open the popup
    if (this.preLoadedCoords) {
      if (closestDistance < 0.0001) {
        closestMarker.openPopup();
      }
      this.preLoadedCoords = null;
    }
  }

  buildPrises(prises: any, isMove?: boolean) {
    // remove all markers
    if (!isMove) {
      this.map.eachLayer((layer: any) => {
        if (layer instanceof L.Marker) {
          this.map.removeLayer(layer);
        }
      });
    } else {
      this.map.eachLayer((layer: any) => {
        // if (layer instanceof L.Marker) {
        if (layer instanceof L.Marker) {
          let loadingZone = this.map.getBounds();

          let i = 0.05;
          loadingZone._northEast.lat += i;
          loadingZone._northEast.lng += i;
          loadingZone._southWest.lat -= i;
          loadingZone._southWest.lng -= i;

          if (!loadingZone.contains(layer.getLatLng())) {
            this.map.removeLayer(layer);
          }
        }
      });
    }

    this.addPrises(prises);
  }

  isDeploye(prise: any): boolean {
    let isDeploye = false;
    if (prise.statut === 'DEPLOYE') return true;
    else return false;
  }

  isCible(prise: any): boolean {
    let isCible = false;
    if (prise.statut == 'CIBLE') return true;
    else return false;
  }

  isDeploiement(prise: any) {
    let isDeploiement = false;
    if (prise.statut == 'EN COURS DE DEPLOIEMENT') return true;
    else return false;
  }

  isRad(prise: any) {
    let isRad = false;
    if (prise.statut !== 'RACCORDABLE DEMANDE') return isRad;
    isRad = true;
    prise.radStatus = [];
    if (prise.go_region === 1) prise.radStatus.push('Go région');
    if (prise.nogo_region === 1) prise.radStatus.push('No Go');
    if (prise.en_attente === 1) prise.radStatus.push('En attente');
    if (prise.prequal === 1) prise.radStatus.push('PREQUAL');
    if (
      prise.go_region === 0 &&
      prise.nogo_region === 0 &&
      prise.en_attente === 0 &&
      prise.prequal === 0
    ) {
      prise.radStatus.push('Autre');
    }
    return isRad;
  }

  isActif(prise: any) {
    if (prise['pto actif'] === 1) return true;
    return false;
  }

  isInactif(prise: any) {
    if (prise['pto inactif'] === 1) return true;
    return false;
  }

  isRaccordement(prise: any) {
    if (prise.a_raccorder === 1) return true;
    return false;
  }

  isPTOVert(prise: any) {
    if (
      prise.a_raccorder === 0 &&
      (prise['pto actif'] != 0 || prise['pto inactif'] != 0)
    ) {
      return true;
    } else return false;
  }

  isPTOOrange(prise: any) {
    if (prise['pto actif'] >= 1 && prise.a_raccorder != 0) {
      return true;
    } else return false;
  }

  isPTORose(prise: any) {
    if (prise['pto actif'] === 0 && prise.a_raccorder != 0) {
      return true;
    } else return false;
  }

  foldSection($event: any) {
    let fieldset;
    let ngicon: any;
    // target is an svg element, fieldset = $event.target.parentElement.parentElement.parentElement;
    // else if target is a path element, fieldset = $event.target.parentElement.parentElement.parentElement.parentElement;
    if ($event.target.tagName === 'svg') {
      fieldset = $event.target.parentElement.parentElement.parentElement;
      ngicon = $event.target.parentElement;
    } else if ($event.target.tagName === 'path') {
      fieldset =
        $event.target.parentElement.parentElement.parentElement.parentElement;
      ngicon = $event.target.parentElement.parentElement;
    }

    let table = fieldset.querySelector('table');
    if (table.style.display === 'none') {
      table.style.display = 'flex';
    } else {
      table.style.display = 'none';
    }

    let otherNgIcon = ngicon.nextElementSibling;
    if (!otherNgIcon) {
      otherNgIcon = ngicon.previousElementSibling;
    }
    otherNgIcon.classList.remove('hidden');
    ngicon.classList.add('hidden');
  }

  satelliteLayer: any;
  planLayer: any;
  toggleVue() {
    if (!this.isSatellite) {
      this.isSatellite = true;
      if (this.planLayer) {
        this.map.removeLayer(this.planLayer);
      }
      this.satelliteLayer = vectorBasemapLayer('arcgis/imagery', {
        apikey: this.arcgis_key,
        language: 'fr',
      }).addTo(this.map);
    } else {
      this.isSatellite = false;
      if (this.satelliteLayer) {
        this.map.removeLayer(this.satelliteLayer);
      }
      this.planLayer = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 19,
          minZoom: 3,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      ).addTo(this.map);
    }
  }

  selectEvent(item: any) {
    this.map.setView([item.center.lat, item.center.lon], 16);
  }

  onChangeSearch(val: string) {}

  onFocused(e: any) {
    // do something when input is focused
  }
}
