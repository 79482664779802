import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service'
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && (error.error.message == 'Expired token' || error.error.message == 'Unauthenticated.')) {
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}