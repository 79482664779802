
<div class="content">
    <div class="form">
        <div class="title">Dashboard Cuivre</div>
        <input type="email" placeholder="Votre e-mail" [(ngModel)]="email">
        <input type="password" placeholder="Votre mot de passe" [(ngModel)]="pass">
        <span *ngIf="loginFailed" class="loginFailed">Identifiant ou mot de passe incorrect</span>
        <button (click)="login()">Se connecter</button>
    </div>
</div>

