import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(public authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const isAuth = localStorage.getItem('token');
    if (isAuth) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
};
