import { Component, NgModule } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  email: string = '';
  pass: string = '';
  loginFailed: boolean = false;

  constructor(private authService: AuthService, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
  }

  login() {
    this.authService.login(this.email, this.pass).subscribe((res) => {
      if (res.access_token) {
        localStorage.setItem('token', res.access_token);
        this.apiService.getParameters().subscribe((res) => {});
        this.apiService.getCities().subscribe((res) => {});
        this.apiService.getEpcis().subscribe((res) => {});
        this.router.navigate(['/']);
      }
    }, (error) => {
      if (error.message === 'Invalid login details') {
        this.loginFailed = true;
      }
    });
  }

}
