import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { NgIconComponent } from '@ng-icons/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgIconComponent, CommonModule, FormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() title!: string;
  isMobile = false;
  platform = 'rosace';

  constructor(private router: Router, private apiService: ApiService) {
    this.platform = localStorage.getItem('platform') || 'rosace';
  }

  ngOnInit() {
    this.isMobile = this.apiService.isMobile;
    this.apiService.isMobileChange.subscribe((res) => {
      this.isMobile = res;
    });

  }

  openMenu() {
    this.apiService.isMenuOpen = !this.apiService.isMenuOpen;
    this.apiService.isMenuOpenChange.next(this.apiService.isMenuOpen);
  }

  changePlatform() {
    this.apiService.platform = this.platform;
    localStorage.setItem('platform', this.platform);
    window.location.reload();
  }
}
