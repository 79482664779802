<div class="header">
    <div>
        {{title}}
        <select name="plateform" id="platform" [(ngModel)]="platform" (change)="changePlatform()">
            <option value="rosace">Rosace</option>
            <option value="losange">Losange</option>
        </select>
    </div>

    <ng-icon *ngIf="isMobile" name="ionMenuOutline" size="2em" (click)="openMenu()"></ng-icon>
    <!-- <ng-icon name="ionMenuOutline" size="2em" (click)="openMenu()"></ng-icon> -->
</div>