import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MapComponent } from './components/map/map.component';
import { DonneesOrangeComponent } from './components/donnees-orange/donnees-orange.component';
import { FermetureCommercialeComponent } from './components/fermeture-commerciale/fermeture-commerciale.component';
import { FermetureTechniqueComponent } from './components/fermeture-technique/fermeture-technique.component';
import { DossierVdrComponent } from './components/dossier-vdr/dossier-vdr.component';
import { DonneesOrangeLosangeComponent } from './components/donnees-orange-losange/donnees-orange-losange.component';
import { DonneesOrangeV3Component } from './components/donnees-orange-v3/donnees-orange-v3.component';
import { RadComponent } from './components/rad/rad.component';
import { VisionCommuneComponent } from './components/vision-commune/vision-commune.component';

import { LoginComponent } from './public/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { PreRaccoComponent } from './components/pre-racco/pre-racco.component';

export const routes: Routes = [
    { path: 'donnees-orange', canActivate: [AuthGuard], component: DonneesOrangeComponent, title: 'Données Orange'},
    { path: 'donnees-orange-v2', canActivate: [AuthGuard], component: DonneesOrangeLosangeComponent, title: 'Données Orange v2'},
    { path: 'donnees-orange-v3', canActivate: [AuthGuard], component: DonneesOrangeV3Component, title: 'Données Orange v3'},
    { path: 'fermeture-commerciale', canActivate: [AuthGuard],component: FermetureCommercialeComponent, title: 'Fermeture Commerciale'},
    { path: 'fermeture-technique', canActivate: [AuthGuard], component: FermetureTechniqueComponent, title: 'Fermeture Technique'},
    { path: 'rad', canActivate: [AuthGuard], component: RadComponent, title: 'Suivi des RADS'},
    { path: 'vision-commune', canActivate: [AuthGuard], component: VisionCommuneComponent, title: 'Vision générale de la commune'},
    { path: 'pre-racco', canActivate: [AuthGuard], component: PreRaccoComponent, title: 'Suivi du pré-raccordement'},
    
    
    { path: 'carte', canActivate: [AuthGuard], component: MapComponent, title: 'Carte'},
    { path: 'dossier-vdr', canActivate: [AuthGuard], component: MapComponent, title: 'Dossier VDR'},
    { path: 'login', component: LoginComponent, title: 'Données Orange'},
    { path: '', redirectTo: '/donnees-orange', pathMatch: 'full' },
    { path: '**', redirectTo: '/donnees-orange', pathMatch: 'full' },
];
