<div class="wrapper">
  <div class="modal-header">
    <div class="tags-wrapper">
      <div class="tag green" *ngIf="rowData.lot">Lot {{ rowData.lot }}</div>
      <div class="tag red" *ngIf="rowData.cat_reco">{{ rowData.cat_reco }}</div>
      <div class="tag red" *ngIf="rowData.motif_bloc">
        {{ rowData.motif_bloc }}
      </div>
    </div>
    <ng-icon
      name="ionCloseOutline"
      class="close"
      (click)="closeModalInfos()"
    ></ng-icon>
  </div>
  <div class="modal-body">
    <div class="infosprise">
      <div class="info">
        <div class="title">IMB</div>
        <div class="value">{{ infos.imb }}</div>
      </div>
      <div class="info">
        <div class="title">Commune</div>
        <div class="value">{{ infos.commune }}</div>
      </div>
      <div class="info">
        <div class="title">Adresse</div>
        <div class="value">{{ infos.voie }}</div>
      </div>
    </div>

    <div class="dossiers-wrapper" *ngIf="allData.length">
      <div class="dossier" *ngFor="let dossier of allData">
        <div class="header">
          <div class="num">
            {{ dossier.num_dossier }} <br />
            <span style="font-size: small; color: gray">{{
              dossier.numdossier_presta
            }}</span>
          </div>
          <div class="cat">{{ dossier.categorie }}</div>
        </div>
        <div class="row">
          <div class="date">{{ dossier.created_at | date : "dd/MM/yyyy" }}</div>
          <div
            class="statut-wrapper"
            [class.green]="dossier.si_statut_code >= 100"
            [class.red]="dossier.si_statut_code < 100"
          >
            <span>{{ dossier.statut }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="seemap" (click)="openMap()">Voir sur la map</div>
    <div class="openvdr">Ouvrir un dossier VDR<br>(bientôt disponible)</div>
  </div>
</div>
