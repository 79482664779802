<div class="wrapper-all-2">
  <div class="content" *ngIf="!loading">
    <div class="parent7" *ngIf="allData" #tableauRef>
      <div class="div1" [id]="allData.imb_ipe.key">
        <span class="num">{{ allData.imb_ipe.rows }}</span
        ><span class="text">Total IMB IPE "{{ platform }}"</span>
        <span class="prises">{{ allData.imb_ipe.data }} prises</span>
      </div>
      <div class="div2" [id]="allData.adresses_orange.key">
        <span class="num">{{ allData.adresses_orange.data }}</span
        ><span class="text">Adresses déclarées par Orange</span>
      </div>

      <div class="div3" [id]="allData.prises_hors_categorie.key">
        <span class="num">{{ allData.prises_hors_categorie.rows }}</span>
        <span class="text">IMB sans correspondance cuivre</span>
        <span class="prises"
          >{{ allData.prises_hors_categorie.data }} prises fibres</span
        >
      </div>

      <div class="div4">
        <span class="num">{{
          allData.imb_cat_1.data + allData.imb_cat_2.data
        }}</span
        ><span class="text"
          >Adresses Cuivre liées à un IMB (après dédoublonnage)</span
        >
        <span class="prises"
          >{{ allData.acces_cuivre_orange.data }} prises</span
        >
      </div>

      <div class="div5">
        <span class="num">{{
          allData.adresses_orange.data -
            (allData.imb_cat_1.data +
              allData.imb_cat_2.data +
              allData.imb_cat_3.data +
              allData.imb_cat_4.data)
        }}</span
        ><span class="text"
          >Adresses orange en doublon (même imb pour plusieurs adresses)</span
        >
      </div>

      <div class="div6 cat-green" [id]="allData.imb_cat_1.key">
        <span class="num">{{ allData.imb_cat_1.rows }}</span
        ><span class="text">Adresses en catégorie 1</span>
      </div>
      <div class="div7 cat-green" [id]="allData.imb_cat_2.key">
        <span class="num">{{ allData.imb_cat_2.rows }}</span
        ><span class="text">Adresses en catégorie 2</span>
      </div>

      <div class="div8" [id]="allData.prise_cat_1.key">
        <span class="num">{{ allData.prise_cat_1.data }}</span
        ><span class="text">prises fibres</span>
      </div>

      <div class="div9" [id]="allData.prise_cat_2.key">
        <span class="num">{{ allData.prise_cat_2.data }}</span
        ><span class="text">prises fibres</span>
      </div>

      <div class="div10 cat-red" [id]="allData.imb_cat_3.key">
        <span class="num">{{ allData.imb_cat_3.data }}</span
        ><span class="text">Adresses en catégorie 3</span>
      </div>
      <div class="div11 cat-red" [id]="allData.imb_cat_4.key">
        <span class="num">{{ allData.imb_cat_4.data }}</span
        ><span class="text">Adresses en catégorie 4</span>
      </div>

      <div class="div12" [id]="allData.prises_hors_categorie.data">
        <span class="num">{{ allData.prises_hors_categorie.data }}</span
        ><span class="text">prises fibres hors catégories</span>
      </div>

      <div class="div13">
        <span class="num">Total PTO</span>
      </div>

      <div class="div14 actif" [id]="allData.actif_cat_1.key">
        <span class="num">{{ allData.actif_cat_1.data }}</span
        ><span class="text">Actif</span>
      </div>
      <div class="div15 actif" [id]="allData.actif_cat_2.key">
        <span class="num">{{ allData.actif_cat_2.data }}</span
        ><span class="text">Actif</span>
      </div>
      <div class="div16 inactif" [id]="allData.inactif_cat_1.key">
        <span class="num">{{ allData.inactif_cat_1.data }}</span
        ><span class="text">Inactif</span>
      </div>
      <div class="div17 inactif" [id]="allData.inactif_cat_2.key">
        <span class="num">{{ allData.inactif_cat_2.data }}</span
        ><span class="text">Inactif</span>
      </div>
      <div class="div18 sans-pto" [id]="allData.sans_pto_cat_1.key">
        <span class="num">{{ allData.sans_pto_cat_1.data }}</span
        ><span class="text">Pto à raccorder</span>
      </div>
      <div class="div19 sans-pto" [id]="allData.sans_pto_cat_2.key">
        <span class="num">{{ allData.sans_pto_cat_2.data }}</span
        ><span class="text">Pto à raccorder</span>
      </div>

      <div class="div20">
        <span class="num">Ecarts</span>
      </div>

      <div class="div21" [id]="allData.imb_ecart_acces2.key">
        <span class="num">{{allData.imb_ecart_acces2.rows}}</span>
        <span class="text">adresses avec gros écart (plus de fibre que de cuivre)</span>
        <span class="text">*sur la base des lignes cuivres actives déclarées</span>

      </div>

      <div class="div22" [id]="allData.imb_ecart_acces.key">
        <span class="num">{{ allData.imb_ecart_acces.rows }}</span
        ><span class="text">adresses avec gros écart (plus de cuivre que de fibre)</span>
      </div>

      <div class="div23 actif" [id]="allData.prises_actives_hors_categorie.key">
        <span class="num">{{ allData.prises_actives_hors_categorie.data }}</span
        ><span class="text">Actif</span>
      </div>

      <div class="div24 inactif" [id]="allData.prises_inactives_hors_categorie.key">
        <span class="num">{{
          allData.prises_inactives_hors_categorie.data
        }}</span>
        <span class="text">Inactif</span>
      </div>
      <div class="div25 sans-pto" [id]="allData.sans_pto_hors_categorie.key">
        <span class="num">{{ allData.sans_pto_hors_categorie.data }}</span
        ><span class="text">Pto à raccorder</span>
      </div>

      <!-- <div class="div28 hidden"></div>
      <div class="div29 hidden"></div>
      <div class="div30 hidden"></div>
      <div class="div9 hidden"></div>
      <div class="div35 hidden"></div>
      <div class="div36 hidden"></div>
      <div class="div22 hidden"></div>
      <div class="div23 hidden"></div>
      <div class="div24 hidden"></div>
      <div class="div25 hidden"></div>
      <div class="div26 hidden"></div>
      <div class="div27 hidden"></div>
      <div class="div12 hidden"></div>
      <div class="div13 hidden"></div> -->
    </div>
    <!-- <app-loader *ngIf="loading" [class]="'parent1'"></app-loader> -->
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent7'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
