<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ filtres.blocage ? filtres.blocage : filtres.text | titlecase }}
    </h4>
    <div class="modal-right">
      <div
        class="pastille"
        style="display: flex; align-items: center"
        (click)="downloadData()"
      >
        <span>Exporter</span>
        <div class="lds-ring" *ngIf="isDownloading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <ng-icon
        name="ionCloseOutline"
        class="close"
        (click)="closeModal()"
      ></ng-icon>
    </div>
  </div>
  <div class="modal-body">
    <div class="lds-ring" *ngIf="loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <!-- <ngx-datatable class="material" *ngIf="!loading" [externalPaging]="true" -->
    <ngx-datatable
      class="material"
      [externalPaging]="true"
      [messages]="{
        emptyMessage: 'Aucune donnée',
        totalMessage: filtres.total
      }"
      [rows]="allData"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [count]="filtres.nb_items_total"
      [offset]="filtres.page"
      (page)="setPage($event)"
      [limit]="10"
      [loadingIndicator]="loading"
      (activate)="openModalInfos($event)"
    ></ngx-datatable>
  </div>
</div>

<app-modal-vue-infos
  *ngIf="isModalInfosOpen"
  (onHide)="closeModalInfos()"
  [dossiers]="dossiers"
  [rowData]="rowData"
></app-modal-vue-infos>
