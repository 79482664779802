<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="parent4" *ngIf="allData" #tableauRef>
      <div class="div1" [id]="allData.totalimb.key">
        <span class="num">{{ allData.totalimb.rows }} IMB</span
        ><span class="text">(Total IPE)</span>
        <span class="text">({{ allData.totalimb.data }} prises)</span>
      </div>
      <div class="div2" [id]="allData.imbsanscuivre.key">
        <span class="num">{{
          allData.imbsanscuivre.data
        }}</span
        ><span class="text">IMB sans cuivre</span>
      </div>
      <div class="div3" [id]="allData.imborange.key">
        <span class="num"
          >{{ allData.imborange.data }} IMB</span
        ><span class="text">(Catégorie 1 et 2)</span>
      </div>
      <div class="div4" [id]="allData.horscategorie.key">
        <span class="num"
          >{{ allData.horscategorie.data }} habitations à trouver</span
        ><span class="text">(Catégorie 3 et 4)</span>
      </div>
      <div class="div5 orange" [id]="allData.ptoimpactant.key">
        <span class="num"
          >{{ allData.ptoimpactant.data }}
        </span>
        <span class="text">PTO à déployer Impactant</span>
      </div>
      <div class="div12" [id]="allData.ptononimpactant.key">
        <span class="num"
          >{{ allData.ptononimpactant.data }}
        </span>
        <span class="text">PTO à déployer non Impactant</span>
      </div>
      <div class="div6 actif" [id]="allData.rafimpactant.key">
        <span class="num">{{
          allData.rafimpactant.data
        }}</span
        ><span class="text">RAF Impactant</span>
      </div>
      <div class="div7 inactif" [id]="allData.radimpactant.key">
        <span class="num">{{ allData.radimpactant.data }}</span
        ><span class="text">RAD Impactant</span>
      </div>
      <div class="div8" [id]="allData.rafnonimpactant.key">
        <span class="num">{{
          allData.rafnonimpactant.data
        }}</span
        ><span class="text">RAF non Impactant</span>
      </div>
      <div class="div9" [id]="allData.radnonimpactant.key">
        <span class="num">{{
          allData.radnonimpactant.data
        }}</span
        ><span class="text">RAD non Impactant</span>
      </div>
      <div class="div10 actif" [id]="allData.totalraf.key">
        <span class="num">{{
          allData.totalraf.data
        }}</span
        ><span class="text">RAF</span>
      </div>
      <div class="div11 inactif" [id]="allData.totalrad.key">
        <span class="num">{{ allData.totalrad.data }}</span
        ><span class="text">RAD</span>
      </div>
    </div>
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent4'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
