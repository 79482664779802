import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, RouterOutlet } from '@angular/router';
import * as L from 'leaflet';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { MapComponent } from './components/map/map.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { FiltersComponent } from './components/filters/filters.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MapComponent,
    HttpClientModule,
    SideMenuComponent,
    FiltersComponent,
    HeaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title: any = 'Données Orange';
  loaded = false;
  filtres: any;
  isMobile = false;
  isMenuOpen = false;
  public isPublic = false;

  constructor(private apiService: ApiService, private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const route = val.url.split('/')[1];
        this.isPublic = false;
        if (route === 'donnees-orange' || route === 'donnees-orange-v3') {
          this.filtres.onglet = '0';
        } else if (route === 'fermeture-commerciale' || route === 'vision-commune') {
          this.filtres.onglet = '1';
        } else if (route === 'fermeture-technique') {
          this.filtres.onglet = '2';
        } else if (route === 'rad') {
          this.filtres.onglet = '3';
        } else if (route.startsWith('carte')) {
          this.filtres.onglet = '4';
        } else if (route === 'donnees-orange-v2') {
          this.filtres.onglet = '5';
        } else if (route === 'pre-racco') {
          this.filtres.onglet = '6';
        } else if (route === 'login') {
          this.filtres.onglet = '0';
          this.isPublic = true;
        }

        this.router.config.forEach((r) => {
          if (route.startsWith(r.path!) && r.path !== '') {
            this.title = r.title;
          }
        });

        if (route !== 'login' && !route.startsWith('carte')) {
          this.apiService.setFiltres(this.filtres);
        }
      }
    });

    this.isMobile = this.apiService.isMobile;
    this.apiService.isMobileChange.subscribe((res) => {
      this.isMobile = res;
    });

    this.apiService.isMenuOpenChange.subscribe((res) => {
      this.isMenuOpen = res;
    });
  }

  ngOnInit() {
    this.filtres = this.apiService.filtres;

    if (localStorage.getItem('token')) {
      this.loaded = true;
      this.apiService.getParameters().subscribe((res) => {});

      this.apiService.getCities().subscribe((res) => {});

      this.apiService.getEpcis().subscribe((res) => {});
    }
  }
}
