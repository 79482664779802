<div class="content" *ngIf="!visionCommune && !visionPreRacco">
  <div class="communes-wrapper">
    <div class="label">
      <span>Choix des communes</span>
      <ng-icon name="ionLocationSharp"></ng-icon>
    </div>
    <div class="row">
      <div
        class="pastille"
        [class.active]="
          !filtres.insee_city.length && !filtres.insee_epci.length
        "
        [class.disabled]="!dropdownListCities"
        (click)="changeCities(null, undefined, true)"
      >
        Toutes les communes
      </div>
      <ng-multiselect-dropdown
        [placeholder]="'Sélectionner une commune'"
        [settings]="dropdownSettingsCities"
        [data]="dropdownListCities"
        [(ngModel)]="selectedItemsCities"
        (onSelect)="changeCities($event)"
        (onDeSelect)="changeCities($event, true)"
        class="dropdown"
        [class.disabled]="!dropdownListCities"
      >
      </ng-multiselect-dropdown>
      <ng-multiselect-dropdown
        [placeholder]="'Sélectionner un EPCI'"
        [settings]="dropdownSettingsEpcis"
        [data]="dropdownListEpcis"
        [(ngModel)]="selectedItemsEpcis"
        (onSelect)="changeEpcis($event)"
        (onDeSelect)="changeEpcis($event, true)"
        class="dropdown"
        [class.disabled]="!dropdownListCities"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
  <div class="lots-wrapper">
    <span>Choix des lots</span>
    <div class="row">
      <div
        class="pastille"
        [class.active]="filtres.lot === 'tous'"
        [class.disabled]="!dropdownListCities"
        (click)="changeLots('tous')"
      >
        Tous les lots
      </div>
      <ng-container *ngIf="params">
        <div
          class="pastille"
          [class.active]="filtres.lot === item"
          [class.disabled]="!dropdownListCities"
          *ngFor="let item of params.lots"
          (click)="changeLots(item)"
        >
          Lot {{ item }}
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="presence-cuivre"
    *ngIf="filtres.onglet == '1' || filtres.onglet == '2'"
  >
    <span>Avec cuivre ?</span>
    <div class="cuivreChoice">
      <div class="choice">
        <input
          type="radio"
          id="nonCuivre"
          name="choixCuivre"
          value="non"
          checked
          (click)="changePresenceCuivre('non')"
        />
        <label for="nonCuivre">Tous</label>
      </div>
      <div class="choice">
        <input
          type="radio"
          id="ouiCuivre"
          name="choixCuivre"
          value="oui"
          (click)="changePresenceCuivre('oui')"
        />
        <label for="ouiCuivre">Oui</label>
      </div>
    </div>
  </div>
  <div class="download-wrapper">
    <button class="download" (click)="downloadFile()">
      Télécharger le fichier traité pour Orange
      <ng-icon name="ionDownloadOutline"></ng-icon>
    </button>
  </div>
</div>
<div class="content" *ngIf="visionCommune">
  <div class="communes-wrapper">
    <div class="label">
      <span>Choix des communes</span>
      <ng-icon name="ionLocationSharp"></ng-icon>
    </div>
    <div class="row">
      <ng-multiselect-dropdown
        [placeholder]="'Sélectionner une commune'"
        [settings]="dropdownSettingsCities"
        [data]="dropdownListCities"
        [(ngModel)]="selectedItemsCities"
        (onSelect)="changeCities($event)"
        (onDeSelect)="changeCities($event, true)"
        class="dropdown"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
</div>

<div class="content" *ngIf="visionPreRacco">
  <div class="lots-wrapper">
    <span>Choix des lots</span>
    <div class="row">
      <div
        class="pastille"
        [class.active]="filtres.lot === 'tous'"
        [class.disabled]="!dropdownListCities"
        (click)="changeLots('tous')"
      >
        Tous les lots
      </div>
      <ng-container *ngIf="params">
        <div
          class="pastille"
          [class.active]="filtres.lot === item"
          [class.disabled]="!dropdownListCities"
          *ngFor="let item of params.lots"
          (click)="changeLots(item)"
        >
          Lot {{ item }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
