import {
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { ModalComponent } from '../../modal/modal.component';
import { DOCUMENT } from '@angular/common';
import 'leader-line';
declare var LeaderLine: any;

import { ApiService } from '../../services/api.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-fermeture-commerciale',
  standalone: true,
  imports: [CommonModule, LoaderComponent, ModalComponent],
  templateUrl: './fermeture-commerciale.component.html',
  styleUrl: './fermeture-commerciale.component.scss',
})
export class FermetureCommercialeComponent {
  allData: any;
  loading: boolean = true;
  filtres: any;
  filtresData: any;
  isModalOpen: boolean = false;
  lines: any = [];
  platform: any = '';

  componentDestroyed$: Subject<boolean> = new Subject()

  @ViewChildren('tableauRef') tableauRef!: any;

  // viewchild the tableauRef element

  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtres = this.apiService.filtres;
    this.platform = localStorage.getItem('platform');

    if (this.apiService.filtres.lot !== null) {
      this.fetchData();
    }

    this.apiService.filtresChange.pipe(takeUntil(this.componentDestroyed$)).subscribe((filtres: any) => {
      this.filtres = filtres;
      if (this.filtres.onglet !== '1') return;
      this.loading = true;
      this.fetchData();
    });
  }

  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        if (child.className && child.className.includes('motifs')) return;
        child.addEventListener('click', () => {
          this.openModal(
            child.id,
            null,
            child.querySelector('.text')?.textContent
          );
        });
      });
    });

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.lines.forEach((line: any) => line.remove());
    this.componentDestroyed$.next(true);
  }

  fetchData() {
    if (this.lines.length) {
      this.lines.forEach((line: any) => line.remove());
    }
    this.lines = [];

    this.apiService.getNumbers(this.filtres).subscribe((res: any) => {
      if (res.success) {
        this.allData = res.data;
        for (let key in this.allData) {
          if (
            typeof this.allData[key] === 'object' &&
            this.allData[key] !== null
          ) {
            this.allData[key].key = key;
          }
        }

        let total_cibles_motifs = 0;
        let total_encours_motifs = 0;

        this.allData.blocages_imb_cibles.forEach((item: any) => {
          total_cibles_motifs += item.total;
        });


        this.allData.blocages_imbs_encours.forEach((item: any) => {
          total_encours_motifs += item.total;
        });


        this.loading = false;
        this.drawLines();
      }
    }, (err: any) => {
    });
  }

  drawLines() {
    setTimeout(() => {
      const lines = [
        { start: '.div1', end: '.div2' },
        { start: '.div7', end: '.div8' },
        { start: '.div8', end: '.div9' },
        { start: '.div9', end: '.div10' },
        { start: '.div10', end: '.div11' },
        { start: '.div13', end: '.div14' },
        { start: '.div14', end: '.div15' },
      ];
      const style = {
        color: '#333',
        size: 1,
        startPlug: 'behind',
        endPlug: 'behind',
      };
      lines.forEach((line: any) => {
        let ref = new LeaderLine(
          this.document.querySelector(line.start),
          this.document.querySelector(line.end),
          style
        );
        this.lines.push(ref);
      });
    });
  }

  openModal(key: any, motif?: any, text?: any) {
    this.isModalOpen = true;
    let nb_items_total;
    let nb_items_prises;
    if (motif) {
      //iterate through allData[key] and find the item with the motif property equal to motif
      this.allData[key].forEach((item: any) => {
        if (item.motif_bloc === motif) nb_items_total = item.total;
      });
    } else if (
      key === 'go_region' ||
      key === 'nogo_region' ||
      key === 'en_attente' ||
      key === 'prequal' ||
      key === 'presence_cuivre'
    ) {
      nb_items_total = this.allData[key].rows;
    } else {
      nb_items_total = this.allData[key].rows;
      nb_items_prises = this.allData[key].data;
    }
    this.filtresData = {
      ref: key,
      lot: this.filtres.lot,
      insee_city: this.filtres.insee_city,
      insee_epci: this.filtres.insee_epci,
      nb_per_page: 10,
      page: 0,
      blocage: motif,
      nb_items_total: nb_items_total,
      nb_items_prises: nb_items_prises,
      avec_cuivre: this.filtres.avec_cuivre,
      text: text,
    };
  }

  closeModal(event: any) {
    this.isModalOpen = false;
  }
}
