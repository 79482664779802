import {
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { ModalComponent } from '../../modal/modal.component';
import { DOCUMENT } from '@angular/common';
import 'leader-line';
declare var LeaderLine: any;
import { NgIconsModule } from '@ng-icons/core';

import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-fermeture-technique',
  standalone: true,
  imports: [CommonModule, LoaderComponent, ModalComponent, NgIconsModule],
  templateUrl: './fermeture-technique.component.html',
  styleUrl: './fermeture-technique.component.scss',
})
export class FermetureTechniqueComponent {
  allData: any;
  loading: boolean = true;
  filtres: any;
  filtresData: any;
  lines: any = [];
  cities: any;
  echecs: any;
  platform: string = 'losange';

  componentDestroyed$: Subject<boolean> = new Subject();

  isModalOpen: boolean = false;

  @ViewChildren('tableauRef') tableauRef!: any;

  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtres = this.apiService.filtres;
    this.platform = this.apiService.platform;
    if (this.apiService.cities) {
      this.cities = this.apiService.cities;
    } else {
      this.apiService.citiesChange.subscribe((res) => {
        this.cities = res;
      });
    }
    if (this.apiService.filtres.lot !== null) {
      this.fetchData();
    }
    this.apiService.filtresChange
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((filtres: any) => {
        this.filtres = filtres;
        if (this.filtres.onglet !== '2') return;
        this.loading = true;
        this.allData = null;
        this.echecs = null;
        this.fetchData();
      });
  }

  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        if (child.className && child.className.includes('motifs')) return;
        let array = this.allData;
        if (child.className && child.className.includes('echec')) {
          array = this.echecs;
        }

        child.addEventListener('click', () => {
          this.openModal(
            child.id,
            array,
            null,
            child.querySelector('.text')?.textContent
          );
        });
      });
    });

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.lines.forEach((line: any) => line.remove());
    this.componentDestroyed$.next(true);
  }

  fetchData() {
    if (this.lines.length) {
      this.lines.forEach((line: any) => line.remove());
    }
    this.lines = [];

    this.apiService.getNumbers(this.filtres).subscribe((res: any) => {
      if (res.success) {
        this.allData = res.data;
        for (let key in this.allData) {
          if (
            typeof this.allData[key] === 'object' &&
            this.allData[key] !== null
          ) {
            this.allData[key].key = key;
          }
        }

        let total_cibles_motifs = 0;
        let total_encours_motifs = 0;

        this.allData.blocages_imb_cibles.forEach((item: any) => {
          total_cibles_motifs += item.total;
        });


        this.allData.blocages_imbs_encours.forEach((item: any) => {
          total_encours_motifs += item.total;
        });


        this.drawLines();
        if (this.echecs && this.apiService.platform === 'losange') {
            this.loading = false;
        } else {
          this.loading = false;
        }
      }
    });

    if (this.apiService.platform === 'rosace') return;
    this.apiService.getEchecCommandes(this.filtres).subscribe((res: any) => {
      this.echecs = res;
      for (let key in this.echecs) {
        if (typeof this.echecs[key] === 'object' && this.echecs[key] !== null) {
          this.echecs[key].key = key;
        }
      }
      if (this.allData) this.loading = false;
    });
  }

  drawLines() {
    setTimeout(() => {
      const lines = [
        // { start: '.div15', end: '.div16' },
        { start: '.div7', end: '.div8' },
        { start: '.div8', end: '.div9' },
        { start: '.div9', end: '.div10' },
        { start: '.div10', end: '.div11' },
      ];
      const style = {
        color: '#333',
        size: 1,
        startPlug: 'behind',
        endPlug: 'behind',
      };
      lines.forEach((line: any) => {
        if (
          !this.document.querySelector(line.start) ||
          !this.document.querySelector(line.end)
        )
          return;

        let ref = new LeaderLine(
          this.document.querySelector(line.start),
          this.document.querySelector(line.end),
          style
        );
        this.lines.push(ref);
      });
    });
  }

  openModal(key: any, array: any, motif?: any, text?: any) {
    this.isModalOpen = true;
    let nb_items_total;
    let nb_items_prises;
    if (motif) {
      array[key].forEach((item: any) => {
        if (item.motif_bloc === motif) nb_items_total = item.total;
      });
    } else if (
      key === 'go_region' ||
      key === 'nogo_region' ||
      key === 'en_attente' ||
      key === 'prequal' ||
      key === 'presence_cuivre'
    ) {
      nb_items_total = array[key].rows;
    } else {
      nb_items_total = array[key].rows;
      nb_items_prises = array[key].data;
    }

    this.filtresData = {
      ref: key,
      lot: this.filtres.lot,
      insee_city: this.filtres.insee_city,
      insee_epci: this.filtres.insee_epci,
      nb_per_page: 10,
      page: 0,
      blocage: motif,
      nb_items_total: nb_items_total,
      nb_items_prises: nb_items_prises,
      avec_cuivre: this.filtres.avec_cuivre,
      text: text,
    };
  }

  closeModal(event: any) {
    this.isModalOpen = false;
  }

  openMapEcarts(event: any) {
    event.stopPropagation();
    let bounds: any = this.getBounds();
    if (bounds) {
      this.router.navigateByUrl(
        `/carte?min_lat=` +
          bounds.min_lat +
          `&min_lon=` +
          bounds.min_lon +
          `&max_lat=` +
          bounds.max_lat +
          `&max_lon=` +
          bounds.max_lon +
          `&ecarts=oui`
      );
    } else {
      this.router.navigateByUrl(`/carte?ecarts=oui`);
    }
  }

  getBounds() {
    let bounds: any;
    let selectedCities = this.cities.filter((city: any) =>
      this.filtres.insee_city.includes(city.insee)
    );
    selectedCities.forEach((city: any) => {
      if (!bounds)
        bounds = {
          min_lat: city.min_latitude,
          max_lat: city.max_latitude,
          min_lon: city.min_longitude,
          max_lon: city.max_longitude,
        };
      else {
        if (city.min_latitude < bounds.min_lat)
          bounds.min_lat = city.min_latitude;
        if (city.max_latitude > bounds.max_lat)
          bounds.max_lat = city.max_latitude;
        if (city.min_longitude < bounds.min_lon)
          bounds.min_lon = city.min_longitude;
        if (city.max_longitude > bounds.max_lon)
          bounds.max_lon = city.max_longitude;
      }
    });
    return bounds;
  }
}
