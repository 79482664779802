import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseApiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) { }

  public getToken() {
    return localStorage.getItem('token');
  }

  public login(email: string, pass: string) {
    return this.http.post(`${this.baseApiUrl}/login`,
      {
        email: email,
        password: pass
      }, {
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => { }),
      map((res: any) => {
        return res;
      })
    );
  }

  public logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
