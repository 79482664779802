<!-- side-menu.component.html -->
<div class="side-menu" [class.mobile]="isMobile">

    <div class="title">Dashboard Cuivre</div>
    <ul>
        <li><a [routerLink]="['/donnees-orange']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Données Orange</a></li>
        <!-- <li><a [routerLink]="['/donnees-orange-v2']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Données Orange v2</a></li> -->
        <li><a [routerLink]="['/donnees-orange-v3']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Données Orange v3</a></li>
        <li><a [routerLink]="['/fermeture-commerciale']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Fermeture commerciale</a></li>
        <li><a [routerLink]="['/fermeture-technique']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Fermeture technique</a></li>
        <li *ngIf="platform === 'losange'"><a [routerLink]="['/rad']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Tableau des RAD</a></li>
        <li><a [routerLink]="['/vision-commune']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Dash Ville</a></li>
        <li><a [routerLink]="['/pre-racco']" routerLinkActive="active"><ng-icon name="ionFileTray"></ng-icon>Suivi pré-racco</a></li>
        <li><a [routerLink]="['/carte']" routerLinkActive="active"><ng-icon name="ionMap"></ng-icon>Carte</a></li>
        <li><a [href]="link" target="_blank"><ng-icon name="ionFileTray"></ng-icon>Dossier VDR</a></li>
    </ul>
    <div class="logout-wrapper">
        <div (click)="logout()" class="logout"><ng-icon name="ionLogOutOutline"></ng-icon><span>Se déconnecter</span></div>
    </div>
</div>

<div class="backdrop" (click)="closeMenu()"></div>