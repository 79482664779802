import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject, catchError, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl = environment.apiUrl;
  token: any;
  tokenChange: Subject<any> = new Subject<any>();
  parameters: any;
  parametersChange: Subject<any> = new Subject<any>();
  cities: any;
  citiesChange: Subject<any> = new Subject<any>();
  epcis: any;
  epcisChange: Subject<any> = new Subject<any>();
  platform: any = 'rosace';

  filtres: any = {
    onglet: null,
    lot: null,
    insee_city: [],
    insee_epci: [],
    avec_cuivre: false,
  };
  filtresChange: Subject<any> = new Subject<any>();

  // httpFormOptions = {
  //   headers: new HttpHeaders({
  //     Authorization: 'Basic ' + btoa(environment.login + ':' + environment.pass),
  //   }),
  // };

  isMobile = false;
  isMobileChange: Subject<any> = new Subject<any>();
  isMenuOpen = false;
  isMenuOpenChange: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 1024;
      this.isMobileChange.next(this.isMobile);
    });

    if (!localStorage.getItem('platform')) {
      localStorage.setItem('platform', this.platform);
    } else {
      this.platform = localStorage.getItem('platform');
    }
  }


  // create a call to https://dev.cuivre.rosace.fibre.services/api/login

  // getToken() {
  //   return this.http.post(`${this.baseApiUrl}/login`, {
  //     email: environment.login,
  //     password: environment.pass
  //   }, this.httpFormOptions).
  //     pipe(
  //       catchError((errorRes) => {
  //         const errorMessage = errorRes.error.message;
  //         throw new Error(errorMessage);
  //       }),
  //       tap((resData) => {}),
  //       map((res) => {
  //         this.token = res;
  //         this.tokenChange.next(this.token);
  //         return res;
  //       })
  //     );
  // }

  getMap(coordinates: any) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get`, {
      min_lat: coordinates.min_lat,
      max_lat: coordinates.max_lat,
      min_lon: coordinates.min_lon,
      max_lon: coordinates.max_lon
    }).
      pipe(
        catchError((errorRes) => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        }),
        tap((resData) => {}),
        map((res) => {
          return res;
        })
      );
  }

  getParameters() {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_parameters`, null).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res: any) => {
        this.parameters = res.data;
        this.parametersChange.next(this.parameters);
        this.filtres.lot = this.parameters.lots[0];
        this.filtresChange.next(this.filtres);
        // this.setFiltres(this.filtres);
        return res;
      })
    );
  }

  getNumbers(filtres: {onglet: string, lot?: string, insee_city: [], insee_epci: [], avec_cuivre: boolean}) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_numbers`, {
      onglet: filtres.onglet,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      avec_cuivre: filtres.avec_cuivre ? true : ''
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getNumbersV2(filtres: {onglet: string, lot?: string, insee_city: [], insee_epci: [], avec_cuivre: boolean}) {
    return this.http.post(`${this.baseApiUrl}/v2/${this.platform}/get_numbers`, {
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getData(filtres: {ref: string, lot?: string, insee_city: [], insee_epci: [], nb_per_page?: string, page?: string, blocage?: string, avec_cuivre: boolean}) {
    
    if (filtres.blocage === 'AUTRE / SANS MOTIF') filtres.blocage = '';
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_data`, {
      ref: filtres.ref,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      nb_per_page: filtres.nb_per_page,
      page: filtres.page,
      blocage: filtres.blocage,
      avec_cuivre: filtres.avec_cuivre,
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getDataCSV(filtres: {ref: string, lot?: string, insee_city: [], insee_epci: [],  nb_items_total?: number, nb_per_page?: string, page?: string, blocage?: string, avec_cuivre: boolean}) {
    if (filtres.blocage === 'AUTRE / SANS MOTIF') filtres.blocage = '';
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_data`, {
      ref: filtres.ref,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      nb_per_page: filtres.nb_items_total,
      page: 0,
      blocage: filtres.blocage,
      avec_cuivre: filtres.avec_cuivre,
      csv: 1
    }, {
      responseType: 'blob'
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  } 

  getDataCSV2(filtres: {ref: string, lot?: string, insee_city: [], insee_epci: [],  nb_items_total?: number, nb_per_page?: string, page?: string, blocage?: string, avec_cuivre: boolean}) {
    if (filtres.blocage === 'AUTRE / SANS MOTIF') filtres.blocage = '';
    return this.http.post(`${this.baseApiUrl}/v2/${this.platform}/get_data`, {
      ref: filtres.ref,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      nb_per_page: filtres.nb_items_total,
      page: 0,
      blocage: filtres.blocage,
      avec_cuivre: filtres.avec_cuivre,
      csv: 1
    }, {
      responseType: 'blob'
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  } 


  getDataV2(filtres: {ref: string, lot?: string, insee_city: [], insee_epci: [], nb_per_page?: string, page?: string, blocage?: string, avec_cuivre: boolean}) {
    
    if (filtres.blocage === 'AUTRE / SANS MOTIF') filtres.blocage = '';
    return this.http.post(`${this.baseApiUrl}/v2/${this.platform}/get_data`, {
      ref: filtres.ref,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
      insee_city: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      nb_per_page: filtres.nb_per_page,
      page: filtres.page,
      blocage: filtres.blocage,
      avec_cuivre: filtres.avec_cuivre
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }
  

  getCities(lot?: string) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_cities`, {
      lot: lot
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res: any) => {
        this.cities = res.data;
        this.citiesChange.next(this.cities);
        return res;
      })
    );
  }

  getEpcis() {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_epcis`, null).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res: any) => {
        this.epcis = res;
        this.epcisChange.next(this.epcis);
        return res;
      })
    );
  }

  setFiltres(filtres: any) {
    this.filtres = filtres;
    this.filtresChange.next(this.filtres);
  }

  getVDR(imb: string) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_vdr`, {
      imb: imb
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        alert(errorMessage);
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getIMBInfo(imb: string) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_imb_info`, {
      imb: imb
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        alert(errorMessage);
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  downloadFile(){
    return this.http.post(`${this.baseApiUrl}/${this.platform}/download`, {}, {
      responseType: 'blob'
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }


  getEchecCommandes(filtres: {ref: string, lot?: string, insee_city: [], insee_epci: [],  nb_items_total?: number, nb_per_page?: string, page?: string, blocage?: string, avec_cuivre: boolean}) {
    
    return this.http.post(`${this.baseApiUrl}/${this.platform}/echec/commandes`, {
      insee: filtres.insee_city.length ? filtres.insee_city.join(',') : null,
      insee_epci: filtres.insee_epci.length ? filtres.insee_epci.join(',') : null,
      lot: filtres.lot === 'tous' ? null : filtres.lot,
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }



  getCityInfo(insee: string) {
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_city_info`, {
      insee: insee
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }


  getGFU(insee: string){
    return this.http.post(`${this.baseApiUrl}/${this.platform}/get_gfu`, {
      insee: insee
    }).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }



  // PreRacco

  getResNumbers(lot: string) {
    let url = `${this.baseApiUrl}/${this.platform}/get_res_numbers`;
    if (lot !== 'tous') url += `?lot=${lot}`;

    return this.http.get(`${url}`).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getLastRes(lot: string) {
    let url = `${this.baseApiUrl}/${this.platform}/last_reservations`;
    if (lot !== 'tous') url += `?lot=${lot}`;

    return this.http.get(`${url}`).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getMonthsInfos(lot: string, month: string){
    let url = `${this.baseApiUrl}/${this.platform}/month_infos?month=${month}`;
    if (lot !== 'tous') url += `&lot=${lot}`;

    return this.http.get(`${url}`).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }

  getDayInfos(lot: string, date: string){
    let url = `${this.baseApiUrl}/${this.platform}/day_infos?date=${date}`;
    if (lot !== 'tous') url += `&lot=${lot}`;

    return this.http.get(`${url}`).pipe(
      catchError((errorRes) => {
        const errorMessage = errorRes.error.error;
        throw new Error(errorMessage);
      }),
      tap((resData) => {}),
      map((res) => {
        return res;
      })
    );
  }
}
