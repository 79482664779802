import { Component, Input, input } from '@angular/core';
import { RouterModule } from '@angular/router'
import { NgIconComponent } from '@ng-icons/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [RouterModule, NgIconComponent, CommonModule],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent {

  @Input() isMobile!: boolean;
  link: any;
  platform: any;
  constructor(private authService: AuthService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.platform = localStorage.getItem('platform');
    if (this.platform === 'rosace') {
      this.link = 'https://vdr.rosace.fibre.services/'
    } else if (this.platform === 'losange') {
      this.link = 'https://vdr.fibre.services/'
    }
  }

  logout() {
    this.authService.logout();
  }

  closeMenu() {
    this.isMobile = false;
    this.apiService.isMenuOpen = false;
    this.apiService.isMenuOpenChange.next(this.apiService.isMenuOpen);
  }
}
