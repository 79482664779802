import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgMultiSelectDropDownModule,
  IDropdownSettings,
} from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { NgIconsModule } from '@ng-icons/core';
import { saveAs } from 'file-saver';

import { ApiService } from '../../services/api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    CommonModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    NgIconsModule,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent {
  params: any;
  filtres: any = {
    onglet: '0',
    lot: null,
    insee_city: [],
    insee_epci: [],
    avec_cuivre: true,
  };
  onglet: any = '1';
  lot: any = null;
  insee_city: any = null;
  insee_epci: any = null;

  dropdownListCities: any = [];
  selectedItemsCities: any = [];
  dropdownSettingsCities: any = {
    singleSelection: false,
    idField: 'insee',
    textField: 'name',
    selectAllText: 'Tous sélectionner',
    unSelectAllText: 'Tous désélectionner',
    searchPlaceholderText: 'Chercher une commune',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    // disabled: true,
    noDataAvailablePlaceholderText: 'Chargement des communes en cours...',
  };

  dropdownListEpcis: any = [];
  selectedItemsEpcis: any = [];
  dropdownSettingsEpcis: any = {
    singleSelection: false,
    idField: 'insee_epci',
    textField: 'nom',
    selectAllText: 'Tous sélectionner',
    unSelectAllText: 'Tous désélectionner',
    searchPlaceholderText: 'Chercher un EPCI',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'Chargement des EPCI en cours...',
  };

  platform: any;
  visionCommune: boolean = false;
  visionPreRacco: boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // listen to route changes

    this.platform = localStorage.getItem('platform');
    this.filtres = this.apiService.filtres;

    this.apiService.filtresChange.subscribe((res) => {
      if (res.lot === 'tous' || this.router.url.includes('/vision-commune')) {
        this.dropdownListCities = this.apiService.cities;
        return;
      }

      if (!this.apiService.cities) return;

      this.dropdownListCities = this.apiService.cities.filter(
        (city: any) => city.lot == this.filtres.lot
      );
    });

    if (window.location.pathname.includes('vision-commune')) {
      this.visionCommune = true;
      this.dropdownSettingsCities.singleSelection = true;
    } else {
      this.visionCommune = false;
      this.dropdownSettingsCities.singleSelection = false;
    }

    if (window.location.pathname.includes('pre-racco')) {
      this.visionPreRacco = true;
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const route = val.url.split('/')[1];
        if (route === 'vision-commune') {
          this.visionCommune = true;
          this.dropdownSettingsCities.singleSelection = true;
          this.dropdownListCities = this.apiService.cities;
        } else {
          this.visionCommune = false;
          this.changeLots(this.filtres.lot);
          this.dropdownSettingsCities.singleSelection = false;
          // this.dropdownListCities = this.apiService.cities;
        }

        if (route === 'pre-racco') {
          this.visionPreRacco = true;
        } else {
          this.visionPreRacco = false;
        }
      }
    });

    if (this.apiService.parameters) {
      this.params = this.apiService.parameters;
    } else {
      this.apiService.parametersChange.subscribe((res) => {
        this.params = res;
      });
    }

    this.loadCities();

    if (this.apiService.epcis) {
      let temp = this.apiService.epcis;
      temp.shift();
      this.dropdownListEpcis = temp;
    } else {
      this.apiService.epcisChange.subscribe((res) => {
        let temp = res;
        temp.shift();
        this.dropdownListEpcis = temp;
      });
    }
  }

  loadCities() {
    if (this.apiService.cities && !this.visionCommune) {
      this.dropdownListCities = this.apiService.cities;
      // filter this.dropdownListCities to keep only the cities where .dashcuivre_lot_id === this.filtres.lot
      this.dropdownListCities = this.dropdownListCities.filter(
        (city: any) => city.lot == this.filtres.lot
      );
    } else {
      this.dropdownListCities = this.apiService.cities;
      
      this.apiService.citiesChange.subscribe((res) => {
        this.dropdownListCities = res;
        if (!this.visionCommune) {
          this.dropdownListCities = this.dropdownListCities.filter(
            (city: any) => city.lot == this.filtres.lot
          );
        }
      });
    }
  }

  changeLots(lot: any) {
    // if (this.filtres.lot == lot) return;
    this.filtres.lot = lot;
    if (lot === 'tous') {
      this.dropdownListCities = this.apiService.cities;
    } else {
      // this.selectedItemsCities = [];

      if (!this.apiService.cities) return;
      this.dropdownListCities = this.apiService.cities.filter(
        (city: any) => city.lot == this.filtres.lot
      );
    }

    this.apiService.setFiltres(this.filtres);
  }
  changeCities(city: any, remove?: boolean, removeAll?: boolean) {
    if (removeAll) {
      this.filtres.insee_city = [];
      this.selectedItemsCities = [];
      this.filtres.insee_epci = [];
      this.selectedItemsEpcis = [];
      this.apiService.setFiltres(this.filtres);
      return;
    }
    if (remove) {
      this.filtres.insee_city = this.filtres.insee_city.filter(
        (insee: any) => insee !== city.insee
      );
      this.filtres.insee_epci = [];
      this.selectedItemsEpcis = [];
    } else if (city.insee) {
      if (!this.visionCommune) {
        this.filtres.insee_city.push(city.insee);
      } else {
        this.filtres.insee_city = [city.insee];
      }
      this.filtres.insee_epci = [];
      this.selectedItemsEpcis = [];
    }
    this.apiService.setFiltres(this.filtres);
  }

  changeEpcis(epci: any, remove?: boolean) {
    if (remove) {
      this.filtres.insee_epci = this.filtres.insee_epci.filter(
        (insee: any) => insee !== epci.insee_epci
      );
    } else if (epci.insee_epci) {
      this.filtres.insee_epci.push(epci.insee_epci);
      this.filtres.insee_city = [];
      this.selectedItemsCities = [];
    }
    this.apiService.setFiltres(this.filtres);
  }

  downloadFile() {
    this.apiService.downloadFile().subscribe((res: any) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'file-' + this.platform + '.xlsx');
    });
  }

  changePresenceCuivre(presence: string) {
    if (presence === 'oui') {
      this.filtres.avec_cuivre = true;
    } else {
      this.filtres.avec_cuivre = false;
    }
    this.apiService.setFiltres(this.filtres);
  }
}
