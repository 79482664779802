<!-- <div [class]="class + ' overlay'" #overlay *ngIf="smartLoader">
    <div *ngFor="let i of layout" [class]="i"></div>
</div>

<div class="loader-wrapper" *ngIf="!smartLoader">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
 -->

<div class="loader-wrapper"></div>

<div *ngIf="class === 'parent3'" class="penetration fake" #fake>
  <div class="num">0%</div>
  <div class="text">Taux de pénétration</div>
</div>

<div *ngIf="class === 'parent8'" class="pre-racco fake" #fake>
  <div class="title">Suivi du pré-raccordement</div>
  <div class="buttons">
    <button class="button">
      Formulaire de réservation
    </button>
    <button class="button">Espace pré-racco VDR</button>
  </div>

  <div class="subtitle">Réservations</div>
</div>

<div [class]="class + ' overlay'" #overlay *ngIf="smartLoader">
  <div *ngFor="let i of layout" [class]="i"></div>
</div>

<div class="lds-ring" *ngIf="!smartLoader">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
