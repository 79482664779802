import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { ModalComponent } from '../../modal/modal.component';
import { DOCUMENT } from '@angular/common';
import 'leader-line';
declare var LeaderLine: any;
import { NgIconsModule } from '@ng-icons/core';

import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-donnees-orange',
  standalone: true,
  imports: [CommonModule, LoaderComponent, ModalComponent, NgIconsModule],
  templateUrl: './donnees-orange.component.html',
  styleUrl: './donnees-orange.component.scss',
})
export class DonneesOrangeComponent {
  allData: any;
  loading: boolean = true;
  filtres: any;
  filtresData: any;
  isModalOpen: boolean = false;
  lines: any = [];
  platform: any = '';
  cities: any;

  componentDestroyed$: Subject<boolean> = new Subject()


  @ViewChildren('tableauRef') tableauRef: any = '';

  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtres = this.apiService.filtres;
    if (this.apiService.cities) {
      this.cities = this.apiService.cities;
    } else {
      this.apiService.citiesChange.subscribe((res) => {
        this.cities = res;
      });
    }

    if (this.apiService.filtres.lot !== null) {
      this.fetchData();
    }

    this.apiService.filtresChange.pipe(takeUntil(this.componentDestroyed$)).subscribe((filtres: any) => {
      this.filtres = filtres;
      if (this.filtres.onglet !== '0') return;
      this.loading = true;
      this.fetchData();
    });

    this.platform = localStorage.getItem('platform');
    if (this.platform == 'rosace') {
      this.platform = 'ROSACE';
    } else if (this.platform == 'losange') {
      this.platform = 'LOSANGE';
    }
  }
  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        child.addEventListener('click', () => {
          this.openModal(
            child.id,
            null,
            child.querySelector('.text')?.textContent
          );
        });
      });
    });

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.lines.forEach((line: any) => line.remove());
    this.lines = [];
    this.componentDestroyed$.next(true);
  }

  fetchData() {
    if (this.lines.length) {
      this.lines.forEach((line: any) => line.remove());
    }
    this.lines = [];

    this.apiService.getNumbers(this.filtres).subscribe((res: any) => {
      if (res.success) {
        this.allData = res.data;
        for (let key in this.allData) {
          if (
            typeof this.allData[key] === 'object' &&
            this.allData[key] !== null
          ) {
            this.allData[key].key = key;
          }
        }
        this.loading = false;
        this.drawLines();
      }
    });
  }

  drawLines() {
    setTimeout(() => {
      const lines = [
        { start: '.div1', end: '.div4' },
        { start: '.div4', end: '.div34' },
        { start: '.div2', end: '.div5' },
        { start: '.div5', end: '.div6' },
        { start: '.div6', end: '.div7' },
        { start: '.div7', end: '.div8' },
        { start: '.div3', end: '.div10' },
        { start: '.div10', end: '.div11' },
        { start: '.div10', end: '.div16' },
        { start: '.div16', end: '.div17' },
        { start: '.div17', end: '.div18' },
        { start: '.div11', end: '.div19' },
        { start: '.div19', end: '.div20' },
        { start: '.div20', end: '.div21' },
        { start: '.div34', end: '.div33' },
        { start: '.div33', end: '.div32' },
        { start: '.div32', end: '.div31' },
      ];
      const style = {
        color: '#333',
        size: 1,
        startPlug: 'behind',
        endPlug: 'behind',
      };
      lines.forEach((line: any) => {
        let ref = new LeaderLine(
          this.document.querySelector(line.start),
          this.document.querySelector(line.end),
          style
        );
        this.lines.push(ref);
      });
    });
  }

  openModal(key: any, motif?: any, text?: any) {
    this.isModalOpen = true;
    let nb_items_total;
    let nb_items_prises;

    if (motif) {
      //iterate through allData[key] and find the item with the motif property equal to motif
      this.allData[key].forEach((item: any) => {
        if (item.motif_bloc === motif) nb_items_total = item.total;
      });
    } else {
      nb_items_total = this.allData[key].rows;
      nb_items_prises = this.allData[key].data;
    }
    this.filtresData = {
      ref: key,
      lot: this.filtres.lot,
      insee_city: this.filtres.insee_city,
      insee_epci: this.filtres.insee_epci,
      nb_per_page: 10,
      page: 0,
      blocage: motif,
      nb_items_total: nb_items_total,
      nb_items_prises: nb_items_prises,
      text: text,
    };
  }

  closeModal(event: any) {
    this.isModalOpen = false;
  }

  openMapCat(event: any) {
    event.stopPropagation();
    let bounds: any = this.getBounds();
    if (bounds) {
      this.router.navigateByUrl(
        `/carte?min_lat=` +
          bounds.min_lat +
          `&min_lon=` +
          bounds.min_lon +
          `&max_lat=` +
          bounds.max_lat +
          `&max_lon=` +
          bounds.max_lon +
          `&imb=non`
      );
    } else {
      this.router.navigateByUrl(`/carte?imb=non`);
    }
  }

  openMapEcarts(event: any) {
    event.stopPropagation();
    let bounds: any = this.getBounds();
    if (bounds) {
      this.router.navigateByUrl(
        `/carte?min_lat=` +
          bounds.min_lat +
          `&min_lon=` +
          bounds.min_lon +
          `&max_lat=` +
          bounds.max_lat +
          `&max_lon=` +
          bounds.max_lon +
          `&ecarts=oui`
      );
    } else {
      this.router.navigateByUrl(`/carte?ecarts=oui`);
    }
  }

  getBounds() {
    let bounds: any;
    let selectedCities = this.cities.filter((city: any) =>
      this.filtres.insee_city.includes(city.insee)
    );
    selectedCities.forEach((city: any) => {
      if (!bounds)
        bounds = {
          min_lat: city.min_latitude,
          max_lat: city.max_latitude,
          min_lon: city.min_longitude,
          max_lon: city.max_longitude,
        };
      else {
        if (city.min_latitude < bounds.min_lat)
          bounds.min_lat = city.min_latitude;
        if (city.max_latitude > bounds.max_lat)
          bounds.max_lat = city.max_latitude;
        if (city.min_longitude < bounds.min_lon)
          bounds.min_lon = city.min_longitude;
        if (city.max_longitude > bounds.max_lon)
          bounds.max_lon = city.max_longitude;
      }
    });
    return bounds;
  }
}
