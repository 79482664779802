import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import * as L from 'leaflet';

@Component({
  selector: 'app-modal-vue-infos',
  standalone: true,
  imports: [NgIconsModule, CommonModule],
  templateUrl: './modal-vue-infos.component.html',
  styleUrl: './modal-vue-infos.component.scss'
})
export class ModalVueInfosComponent {
  private map: any;

  coordinates: any = {
    lat: 48.0464746817066,
    lon: 7.129668002087137,
  }

  allData: any = [];
  infos: any = {
    imb: '',
    commune: '',
    voie: ''
  }

  @Output() onHide = new EventEmitter<boolean>();
  @Input() dossiers: any;
  @Input() rowData: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.allData = this.dossiers;
    if (this.rowData.IdentifiantImmeuble) {
      this.infos.imb = this.rowData.IdentifiantImmeuble;
      this.infos.commune = this.rowData.CommuneImmeuble;
      this.infos.voie = this.rowData.NumeroVoieImmeuble + ' ' + this.rowData.TypeVoieImmeuble + ' ' + this.rowData.NomVoieImmeuble;
    }else {
      this.infos.imb = this.rowData.ref_imb;
      this.infos.commune = this.rowData.lib_com;
      this.infos.voie = this.rowData.num_adr + ' ' + this.rowData.voie_adr;
    }

    this.initMap();
  }

  initMap() {
    let coordinates;
    if (this.rowData.IdentifiantImmeuble) {
      coordinates = this.lambert93toWGPS(this.rowData.CoordonneeImmeubleX, this.rowData.CoordonneeImmeubleY);
    } else {
      coordinates = this.lambert93toWGPS(this.rowData.lat, this.rowData.lng);
    }

    this.map = L.map('map').setView([coordinates[0], coordinates[1]], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
    }).addTo(this.map);

    // set the zoom level to 17
    this.map.setZoom(17);

    const icon = L.icon({
      iconUrl: 'assets/marker-blue.png',
      iconSize: [32, 32],
    })

    // add a marker at the coordinates
    L.marker([coordinates[0], coordinates[1]], {icon: icon}).addTo(this.map)
      .bindPopup('Immeuble: ' + this.infos.imb + '<br>Commune: ' + this.infos.commune + '<br>Voie: ' + this.infos.voie)
  }

  closeModalInfos() {
    this.onHide.emit(true);
  }

  openMap() {
    //  reroute to map page with the parameter id to 1
    let coordinates;
    if (this.rowData.IdentifiantImmeuble) {
      coordinates = this.lambert93toWGPS(this.rowData.CoordonneeImmeubleX, this.rowData.CoordonneeImmeubleY);
    } else {
      coordinates = this.lambert93toWGPS(this.rowData.lat, this.rowData.lng);
    }

    // this.router.navigateByUrl(`/carte?lat=` + coordinates[0] + `&lon=` + coordinates[1]);

    // open in a new tab instead of the current tab
    window.open(`/carte?lat=` + coordinates[0] + `&lon=` + coordinates[1], '_blank');
  }

  mathTanH(x: any) {
    if (x === Infinity) {
      return 1;
    } else if (x === -Infinity) {
      return -1;
    } else {
      return (Math.exp(x) - Math.exp(-x)) / (Math.exp(x) + Math.exp(-x));
    }
  }

  mathAtanh(x: any) {
    return Math.log((1 + x) / (1 - x)) / 2;

  }

  lambert93toWGPS(lambertE: any, lambertN: any) {
    let constantes = {
      GRS80E: 0.081819191042816,
      LONG_0: 3,
      XS: 700000,
      YS: 12655612.0499,
      n: 0.725607765053267,
      C: 11754255.4261,
    };

    let delX = lambertE - constantes.XS;
    let delY = lambertN - constantes.YS;
    let gamma = Math.atan(-delX / delY);
    let R = Math.sqrt(delX * delX + delY * delY);
    let latiso = Math.log(constantes.C / R) / constantes.n;
    let sinPhiit0 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * Math.sin(1))
    );
    let sinPhiit1 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit0)
    );
    let sinPhiit2 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit1)
    );
    let sinPhiit3 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit2)
    );
    let sinPhiit4 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit3)
    );
    let sinPhiit5 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit4)
    );
    let sinPhiit6 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit5)
    );

    let longRad = Math.asin(sinPhiit6);
    let latRad = gamma / constantes.n + (constantes.LONG_0 / 180) * Math.PI;

    let longitude = (latRad / Math.PI) * 180;
    let latitude = (longRad / Math.PI) * 180;

    return [latitude, longitude];
  }
}
