import { ChangeDetectorRef, Component, ViewChildren } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { ApiService } from '../../services/api.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ModalComponent } from '../../modal/modal.component';
import * as L from 'leaflet';

@Component({
  selector: 'app-vision-commune',
  standalone: true,
  imports: [LoaderComponent, CommonModule, NgIconsModule, ModalComponent],
  templateUrl: './vision-commune.component.html',
  styleUrl: './vision-commune.component.scss',
})
export class VisionCommuneComponent {
  loading: boolean = false;
  @ViewChildren('tableauRef') tableauRef!: any;

  filtres: any;
  platform: any = '';
  cities: any;
  selectedCity: any;

  allData: any;

  constructor(private apiService: ApiService, private cd: ChangeDetectorRef) {}

  componentDestroyed$: Subject<boolean> = new Subject();

  ngOnInit() {
    this.filtres = this.apiService.filtres;

    if (this.apiService.cities) {
      this.cities = this.apiService.cities;
    } else {
      this.apiService.citiesChange.subscribe((res) => {
        this.cities = res;
      });
    }

    this.apiService.filtresChange
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((filtres: any) => {
        this.filtres = filtres;
        if (this.filtres.onglet !== '1') return;
        if (this.filtres.insee_city.length == 0) return;
        this.selectedCity = this.cities.find(
          (city: any) => city.insee == this.filtres.insee_city[0]
        );
        this.loading = true;
        this.fetchData();
      });

    this.platform = localStorage.getItem('platform');
    if (this.platform == 'rosace') {
      this.platform = 'ROSACE';
    } else if (this.platform == 'losange') {
      this.platform = 'LOSANGE';
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.filtres.insee_city = [];
  }

  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        if (child.className && child.className.includes('motifs')) return;
        child.addEventListener('click', () => {
          this.openModal(
            child.id,
            null,
            child.querySelector('.text')?.textContent
          );
        });
      });
    });

    this.cd.detectChanges();
  }

  fetchData() {
    this.allData = {};

    combineLatest([
      this.apiService.getCityInfo(this.selectedCity.insee),
      this.apiService.getGFU(this.selectedCity.insee),
      this.apiService.getNumbers({
        onglet: '1',
        lot: '',
        insee_city: this.filtres.insee_city,
        insee_epci: this.filtres.insee_epci,
        avec_cuivre: this.filtres.avec_cuivre,
      }),
      this.apiService.getNumbers({
        onglet: '2',
        lot: '',
        insee_city: this.filtres.insee_city,
        insee_epci: this.filtres.insee_epci,
        avec_cuivre: this.filtres.avec_cuivre,
      }),
    ]).subscribe(([cityInfo, gfu, numbers1, numbers2]: any) => {
      this.formatData([cityInfo, gfu, numbers1, numbers2]);
    });
  }

  formatData([cityInfo, gfu, numbers1, numbers2]: any) {
    this.allData = undefined;
    if (cityInfo.success) {
      this.allData = cityInfo.data;

      // go threw each key in this.allData, if it starts with 'nb', transform it into an object like this:
      // { rows: ..., data: ... }

      for (let key in this.allData) {
        if (key.startsWith('nb')) {
          this.allData[key] = {
            rows: this.allData[key],
            data: this.allData[key],
          };
        }
      }
    }

    if (gfu.success) {
      this.allData.gfus = gfu.data.gfus[0];
      // filter this.allData.gfus.details keeping only the items where .cls is not null
      if (this.allData.gfus) {
        this.allData.gfus.details = this.allData.gfus.details.filter(
          (item: any) => item.cls !== null
        );
      }
    }

    if (numbers1.success) {
      this.allData.dlpi_apres_2025 = numbers1.data.dlpi_apres_2025;
      this.allData.dlpi_avant_2025 = numbers1.data.dlpi_avant_2025;
      this.allData.imb_projetfutur = numbers1.data.imb_projetfutur;
      this.allData.imb_rad = numbers1.data.imb_rad;
    }

    if (numbers2.success) {
      this.allData.imb_ipe_deployes = numbers2.data.imb_ipe_deployes;
      this.allData.imb_cibles = numbers2.data.imb_cibles;
      this.allData.imb_en_cours = numbers2.data.imb_en_cours;
      this.allData.pto_actif = numbers2.data.pto_actif;
      this.allData.pto_inactif = numbers2.data.pto_inactif;
      this.allData.sans_pto = numbers2.data.sans_pto;
      this.allData.gels_commerciaux = numbers2.data.gels_commerciaux;
      this.allData.blocages_imb_cibles = numbers2.data.blocages_imb_cibles;
      this.allData.blocages_imbs_encours = numbers2.data.blocages_imbs_encours;
    }

    for (let key in this.allData) {
      if (typeof this.allData[key] === 'object' && this.allData[key] !== null) {
        this.allData[key].key = key;
      }
    }

    this.loading = false;
    if (this.allData.gfus && this.allData.gfus.details.length > 0) {
      setTimeout(() => {
        this.initMap();
      }, 500);
    }
  }

  isModalOpen: boolean = false;
  filtresData: any;

  openModal(key: any, motif?: any, text?: any) {
    if (!this.allData[key]) return;

    this.isModalOpen = true;
    let nb_items_total;
    let nb_items_prises;
    if (motif) {
      //iterate through allData[key] and find the item with the motif property equal to motif
      this.allData[key].forEach((item: any) => {
        if (item.motif_bloc === motif) nb_items_total = item.total;
      });
    } else if (
      key === 'go_region' ||
      key === 'nogo_region' ||
      key === 'en_attente' ||
      key === 'prequal' ||
      key === 'presence_cuivre'
    ) {
      nb_items_total = this.allData[key].rows;
    } else {
      nb_items_total = this.allData[key].rows;
      nb_items_prises = this.allData[key].data;
    }
    this.filtresData = {
      ref: key,
      lot: null,
      insee_city: this.filtres.insee_city,
      insee_epci: this.filtres.insee_epci,
      nb_per_page: 10,
      page: 0,
      blocage: motif,
      nb_items_total: nb_items_total,
      nb_items_prises: nb_items_prises,
      avec_cuivre: this.filtres.avec_cuivre,
      text: text,
    };
  }

  closeModal(event: any) {
    this.isModalOpen = false;
  }

  openMap(lien: any) {
    const lat = this.dmsToDecimal(lien.latitude.trim());
    const lng = this.dmsToDecimal(lien.longitude.trim());

    // go to the coordinates on the map

    this.map.setView([lat, lng], 19);

    // window.open(`/carte?lat=` + lat + `&lon=` + lng, '_blank');
  }

  dmsToDecimal(dms: string): number {
    const dmsRegex = /(\d+)°(\d+)'(\d+(\.\d+)?)\"([NSEW])/;
    const matches = dms.match(dmsRegex);

    if (!matches) {
      throw new Error('Invalid DMS format');
    }

    const degrees = parseFloat(matches[1]);
    const minutes = parseFloat(matches[2]);
    const seconds = parseFloat(matches[3]);
    const direction = matches[5];

    let decimal = degrees + minutes / 60 + seconds / 3600;

    if (direction === 'S' || direction === 'W') {
      decimal = -decimal;
    }

    return decimal;
  }

  map: any;

  initMap() {
    let coordinates;
    const lat = this.dmsToDecimal(this.allData.gfus.details[0].latitude);
    const lng = this.dmsToDecimal(this.allData.gfus.details[0].longitude);

    this.map = L.map('map').setView([lat, lng], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
    }).addTo(this.map);

    // set the zoom level to 17
    this.map.setZoom(17);

    const iconGreen = L.icon({
      iconUrl: 'assets/images/camera_green.png',
      iconSize: [24, 24],
    });

    const iconOrange = L.icon({
      iconUrl: 'assets/images/camera_orange.png',
      iconSize: [24, 24],
    })

    // for each item in this.allData.gfus.details, add a marker to the map
    this.allData.gfus.details.forEach((item: any) => {
      const lat = this.dmsToDecimal(item.latitude);
      const lng = this.dmsToDecimal(item.longitude);
      const icon = item.date_mise_en_service && item.date_mise_en_service.length ? iconGreen : iconOrange;
      L.marker([lat, lng], { icon: icon })
        .addTo(this.map)
        .bindPopup(
          'Lien: ' +
            item.cls +
            '<br>Adresse: ' +
            item.address
        );
    });

    // add a marker at the coordinates
    // L.marker([coordinates[0], coordinates[1]], { icon: icon })
    //   .addTo(this.map)
    //   .bindPopup(
    //     'Immeuble: ' +
    //       this.infos.imb +
    //       '<br>Commune: ' +
    //       this.infos.commune +
    //       '<br>Voie: ' +
    //       this.infos.voie
    //   );
  }
}
