<!-- <div class="map-container">
    <div class="map-frame">
        <div id="map"></div>
    </div>
    <div class="loading" *ngIf="loading">
        Chargement en cours...
    </div>
</div> -->

<div class="map-container">
  <div class="filters-column">
    <!-- Put your filters here -->
    <div class="ng-autocomplete" *ngIf="filters">
      <ng-autocomplete
        [data]="cities"
        [searchKeyword]="'name'"
        placeholder="Chercher une commune"
        (selected)="selectEvent($event)"
        (inputChanged)="onChangeSearch($event)"
        (inputFocused)="onFocused($event)"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
      ></ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="'Aucune commune trouvée'"></div>
      </ng-template>
    </div>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>
        <img src="./../../../assets/images/house_green.png" />Deployé
        <input
          type="checkbox"
          [(ngModel)]="filters.all_deploye"
          (change)="changeDeploye(null, filters.all_deploye)"
        />
        <!-- <ng-icon class="fold" name="ionCaretDownOutline" (click)="foldSection($event)"></ng-icon> -->
        <ng-icon
          class="fold down hidden"
          name="ionCaretDownOutline"
          (click)="foldSection($event)"
        ></ng-icon>
        <ng-icon
          class="fold up"
          name="ionCaretUpOutline"
          (click)="foldSection($event)"
        ></ng-icon>
      </legend>
      <table>
        <tbody>
          <tr *ngFor="let item of params.deploye; index as i">
            <span>{{ item.name }}</span>
            <input
              type="checkbox"
              [(ngModel)]="filters.deploye[i].is_selected"
              (change)="changeDeploye(item.id)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset cible" *ngIf="filters">
      <legend>
        <img src="./../../../assets/images/house_grey.png" />Cible
        <input
          type="checkbox"
          name="cible_all"
          [(ngModel)]="filters.cible_on"
          (change)="changeCible(undefined, undefined, filters.cible_on)"
        /><ng-icon
          class="fold down"
          name="ionCaretDownOutline"
          (click)="foldSection($event)"
        ></ng-icon>
        <ng-icon
          class="fold up hidden"
          name="ionCaretUpOutline"
          (click)="foldSection($event)"
        ></ng-icon>
      </legend>
      <!-- <legend>Cible </legend> -->
      <table style="display: none">
        <tbody>
          <tr *ngFor="let item of params.motifs; index as i">
            {{
              item
            }}
            <input
              type="checkbox"
              [(ngModel)]="filters.motifs_blocage_cible[i].is_selected"
              (change)="changeCible(i, item)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>
        <img src="./../../../assets/images/house_pink.png" />En cours de
        deploiement
        <input
          type="checkbox"
          name="encours_all"
          [(ngModel)]="filters.encours_on"
          (change)="changeEncours(undefined, undefined, filters.encours_on)"
        /><ng-icon
          class="fold down"
          name="ionCaretDownOutline"
          (click)="foldSection($event)"
        ></ng-icon>
        <ng-icon
          class="fold up hidden"
          name="ionCaretUpOutline"
          (click)="foldSection($event)"
        ></ng-icon>
      </legend>
      <table style="display: none">
        <tbody>
          <tr *ngFor="let item of params.motifs; index as i">
            {{
              item
            }}
            <input
              type="checkbox"
              name="encours"
              [(ngModel)]="filters.motifs_blocage_encours[i].is_selected"
              (change)="changeEncours(i, item)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>
        <img src="./../../../assets/images/house_orange.png" />Raccordable
        demande
        <input
          type="checkbox"
          name="rad_all"
          [(ngModel)]="filters.rad_on"
          (change)="changeRad(null, filters.rad_on)"
        /><ng-icon
          class="fold down hidden"
          name="ionCaretDownOutline"
          (click)="foldSection($event)"
        ></ng-icon>
        <ng-icon
          class="fold up"
          name="ionCaretUpOutline"
          (click)="foldSection($event)"
        ></ng-icon>
      </legend>
      <table>
        <tbody>
          <tr *ngFor="let item of params.rad; index as i">
            {{
              item.name
            }}
            <input
              type="checkbox"
              name="rad"
              (value)="(filters.rad[i].is_selected)"
              [(ngModel)]="filters.rad[i].is_selected"
              (change)="changeRad(item.id)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>
        PTO
        <input
          type="checkbox"
          [(ngModel)]="filters.all_pto"
          (change)="changePTO(null, filters.all_pto)"
        /><ng-icon
          class="fold down hidden"
          name="ionCaretDownOutline"
          (click)="foldSection($event)"
        ></ng-icon>
        <ng-icon
          class="fold up"
          name="ionCaretUpOutline"
          (click)="foldSection($event)"
        ></ng-icon>
      </legend>
      <table>
        <tbody>
          <tr>
            {{
              params.pto[0].name
            }}
            <input
              type="checkbox"
              [(ngModel)]="filters.pto[0].is_selected"
              (change)="changePTO(params.pto[0].id)"
            />
          </tr>
          <tr>
            {{
              params.pto[2].name
            }}
            <input
              type="checkbox"
              [(ngModel)]="filters.pto[2].is_selected"
              (change)="changePTO(params.pto[2].id)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <table>
        <tbody>
          <tr>
            {{
              params.pto[1].name
            }}
            <input
              type="checkbox"
              [(ngModel)]="filters.pto[1].is_selected"
              (change)="changePTO(params.pto[1].id)"
            />
          </tr>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>Présence cuivre</legend>
      <table>
        <tbody class="cuivreChoice">
          <div class="choice">
            <input
              type="radio"
              id="tousCuivre"
              name="choixCuivre"
              value="tous"
              (click)="changePresenceCuivre('tous')"
              checked
            />
            <label for="tousCuivre">Tous</label>
          </div>
          <div class="choice">
            <input
              type="radio"
              id="ouiCuivre"
              name="choixCuivre"
              value="oui"
              (click)="changePresenceCuivre('oui')"
            />
            <label for="tousCuivre">Oui</label>
          </div>
          <div class="choice">
            <input
              type="radio"
              id="nonCuivre"
              name="choixCuivre"
              value="non"
              (click)="changePresenceCuivre('non')"
            />
            <label for="nonCuivre">Non</label>
          </div>
        </tbody>
      </table>
    </fieldset>
    <fieldset class="fieldset" *ngIf="filters">
      <legend>Prises avec IMB ?</legend>
      <table>
        <tbody class="imbChoice">
          <div class="choice">
            <input
              type="radio"
              id="tousIMB"
              name="choixIMB"
              value="tous"
              (click)="changeIMB('tous')"
              [checked]="filters.imb.all == true"
            />
            <label for="tousIMB">Tous</label>
          </div>
          <div class="choice">
            <input
              type="radio"
              id="ouiIMB"
              name="choixIMB"
              value="oui"
              (click)="changeIMB('oui')"
              [checked]="filters.imb.oui == true"
            />
            <label for="ouiIMB">Oui</label>
          </div>
          <div class="choice">
            <input
              type="radio"
              id="nonIMB"
              name="choixIMB"
              value="non"
              (click)="changeIMB('non')"
              [checked]="filters.imb.non == true"
            />
            <label for="nonIMB">Non</label>
            <img src="./../../../assets/images/house_red.png" />
          </div>
          <!-- <input type="radio" id="tousIMB" name="choixIMB" value="tous" (click)="changeIMB('tous')" checked />
                    <label for="tousIMB">Tous</label>
                    <input type="radio" id="ouiIMB" name="choixIMB" value="oui" (click)="changeIMB('oui')" />
                    <label for="ouiIMB">Oui</label>
                    <input type="radio" id="nonIMB" name="choixIMB" value="non" (click)="changeIMB('non')" />
                    <label for="nonIMB">Non</label>
                    <img src="./../../../assets/images/house_red.png"> -->
        </tbody>
      </table>
    </fieldset>
  </div>
  <div class="map-and-loading">
    <div class="map-frame">
      <div id="map"></div>
    </div>
    <div class="loading" *ngIf="loading">
      <!-- <div class="loading"> -->
      Chargement en cours...
    </div>
    <div class="help" *ngIf="mapZoom < 16">
      <!-- <div class="loading"> -->
      Zoomez pour afficher les prises
    </div>
    <div class="toggle-vue" *ngIf="filters" (click)="toggleVue()">
      <ng-icon
        name="ionMap"
        title="Passer en vue Plan"
        *ngIf="isSatellite"
      ></ng-icon>
      <ng-icon
        name="ionImage"
        title="Passer en vue Satellite"
        *ngIf="!isSatellite"
      ></ng-icon>
    </div>
  </div>
</div>
