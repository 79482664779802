<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="parent5" *ngIf="allData" #tableauRef>
      <div class="div1 bleu" [id]="allData.total_rad.key">
        <span class="num">{{ allData.total_rad.data }}</span
        ><span class="text">Total RAD</span>
      </div>
      <div class="div2" [id]="allData.total_rad_sans_cuivre.key">
        <span class="num">{{ allData.total_rad_sans_cuivre.data }}</span
        ><span class="text">RAD sans cuivre</span>
      </div>
      <div class="div3 orange" [id]="allData.total_rad_cuivre.key">
        <span class="num">{{ allData.total_rad_cuivre.data }}</span
        ><span class="text">RAD cuivre</span>
      </div>
      <div class="div4 actif" [id]="allData.rad_deployes.key">
        <span class="num">{{ allData.rad_deployes.data }}</span
        ><span class="text">RAD déployés</span>
      </div>
      <div class="div5 inactif" [id]="allData.rad_en_attente.key">
        <span class="num">{{ allData.rad_en_attente.data }}</span
        ><span class="text">RAD en attente</span>
      </div>
      <div class="div6 orange" [id]="allData.rad_en_non_demande.key">
        <span class="num">{{ allData.rad_en_non_demande.data }}</span
        ><span class="text">RAD non demandé</span>
      </div>
      <div class="div7" [id]="allData.total_pto.key">
        <span class="num">{{ allData.total_pto.data }}</span
        ><span class="text">Total PTO</span>
      </div>
      <div class="div8 actif" [id]="allData.total_pto_actif.key">
        <span class="num">{{ allData.total_pto_actif.data }}</span
        ><span class="text">PTO actifs</span>
      </div>
      <div class="div9 inactif" [id]="allData.total_pto_inactif.key">
        <span class="num">{{ allData.total_pto_inactif.data }}</span
        ><span class="text">PTO inactifs</span>
      </div>
      <div class="div10 orange" [id]="allData.total_pto_manquant.key">
        <span class="num">{{ allData.total_pto_manquant.data }}</span
        ><span class="text">Actions commerciales à réaliser</span>
      </div>
    </div>
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent5'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
