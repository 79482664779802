<div class="wrapper" *ngIf="!isPublic">
    <app-side-menu [isMobile]="false" *ngIf="!isMobile"></app-side-menu>
    <app-side-menu [isMobile]="true" *ngIf="isMobile && isMenuOpen"></app-side-menu>
    <!-- <div class="content" *ngIf="loaded"> -->
    <div class="content">
        <app-header [title]="title"></app-header>
        <app-filters *ngIf="filtres.onglet != 4"></app-filters>
        <router-outlet style="flex-grow: 1;"></router-outlet>
    </div>
</div>

<div class="wrapper" *ngIf="isPublic">
    <router-outlet style="flex-grow: 1;"></router-outlet>

</div>