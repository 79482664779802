<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="date" *ngIf="allData && allData.starting_date">
      Lot {{ filtres.lot }} prévue le
      {{ allData.starting_date | date : "longDate" }}
    </div>
    <div class="parent2" *ngIf="allData" #tableauRef>
      <div class="div1 actif" [id]="allData.imb_ipe_deployes.key">
        <span class="num">{{ allData.imb_ipe_deployes.rows }}</span
        ><span class="text">IMB Déployé</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div2" [id]="allData.gels_commerciaux.key">
        <span class="num">{{ allData.gels_commerciaux.rows }}</span
        ><span class="text">Gel commerciaux</span>
        <span class="source">Source: IPE</span>
      </div>

      <div class="div3 inactif" [id]="allData.imb_cibles.key">
        <span class="num">{{ allData.imb_cibles.rows }}</span
        ><span class="text">IMB Cible</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div4 motifs">
        <span class="source_motif" *ngIf="allData.blocages_imb_cibles.length">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imb_cibles"
          (click)="openModal(allData.blocages_imb_cibles.key, item.motif_bloc)"
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div5 inactif" [id]="allData.imb_en_cours.key">
        <span class="num">{{ allData.imb_en_cours.rows }}</span
        ><span class="text">IMB En cours de déploiement</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div6 motifs">
        <span class="source_motif" *ngIf="allData.blocages_imbs_encours.length">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imbs_encours"
          (click)="
            openModal(allData.blocages_imbs_encours.key, item.motif_bloc)
          "
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div7 inactif" [id]="allData.imb_rad.key">
        <span class="num">{{ allData.imb_rad.rows }}</span
        ><span class="text">IMB - RAD</span>
        <span class="source">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
      </div>
      <div class="div8" *ngIf="allData.go_region" [id]="allData.go_region.key">
        <span class="num">{{ allData.go_region.rows }}</span
        ><span class="text">GO REGION</span
        ><span class="text green" *ngIf="platform === 'rosace'"
          >{{ allData.go_region.data }} € en cours</span
        >
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div9"
        *ngIf="allData.nogo_region"
        [id]="allData.nogo_region.key"
      >
        <span class="num">{{ allData.nogo_region.rows }}</span
        ><span class="text">NOGO REGION</span
        ><span class="text red" *ngIf="platform === 'rosace'"
          >{{ allData.nogo_region.data }} € refusé</span
        >
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div10"
        *ngIf="allData.en_attente"
        [id]="allData.en_attente.key"
      >
        <span class="num">{{ allData.en_attente.rows }}</span
        ><span class="text">EN ATTENTE</span
        ><span class="text red" *ngIf="platform === 'rosace'"
          >{{ allData.en_attente.data }} € en attente</span
        >
        <span class="source">Source: IPE</span>
      </div>
      <div class="div11" *ngIf="allData.prequal" [id]="allData.prequal.key">
        <span class="num">{{ allData.prequal.rows }}</span
        ><span class="text">PREQUAL</span
        ><span class="text red" *ngIf="platform === 'rosace'"
          >{{ allData.prequal.data }} € non demandé</span
        >
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div12"
        *ngIf="allData.presence_cuivre"
        [id]="allData.presence_cuivre.key"
      >
        <span class="num">{{ allData.presence_cuivre.rows }}</span
        ><span class="text">Total présence cuivre</span
        ><span class="text red"
          >{{ allData.presence_cuivre.data }} € non demandé</span
        >
        <span class="source">Source: Orange</span>
      </div>

      <div class="div13" [id]="allData.imb_projetfutur.key">
        <span class="num">{{ allData.imb_projetfutur.rows }}</span
        ><span class="text">Projet Futur</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div14" [id]="allData.dlpi_avant_2025.key">
        <span class="num">{{ allData.dlpi_avant_2025.rows }}</span
        ><span class="text">DLPI < 2025</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div15" [id]="allData.dlpi_apres_2025.key">
        <span class="num">{{ allData.dlpi_apres_2025.rows }}</span
        ><span class="text">DLPI > 2025</span>
        <span class="source">Source: IPE</span>
      </div>
    </div>
    <!-- <app-loader *ngIf="loading" [class]="'parent2'"></app-loader> -->
  </div>
  <app-loader
    *ngIf="loading"
    class="host"
    [originalTableau]="tableauRef"
    [class]="'parent2'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
