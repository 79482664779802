<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="title">Suivi du pré-raccordement</div>
    <div class="buttons">
      <button class="button" (click)="goToForm()">
        Formulaire de réservation
      </button>
      <button class="button" (click)="goToVDR()">Espace pré-racco VDR</button>
    </div>

    <div class="subtitle">Réservations</div>

    <div class="parent8" #tableauRef>
      <div class="div1 bleu">
        <span class="num">{{ resNumbers.reservations_total }}</span
        ><span class="text italic">réservations totales</span>
      </div>
      <div class="div2 vert">
        <span class="num">{{ resNumbers.raccordes }}</span
        ><span class="text italic">raccordés</span>
      </div>
      <div class="div3 orange">
        <span class="num">{{ resNumbers.echecs }}</span
        ><span class="text italic">échecs</span>
      </div>
      <div class="div4 rouge">
        <span class="num">{{ resNumbers.attentes }}</span
        ><span class="text italic">en attente de réservation</span>
      </div>
      <div class="div5" [class.loading]="loadingDay">
        <full-calendar
          [options]="calendarOptions"
          [deepChangeDetection]="true"
        ></full-calendar>
      </div>
      <div class="div6 orange">
        <div class="subtitle">Dernières réservations</div>
        <div class="res-wrapper">
          <div class="no-data" *ngIf="lastRes && !lastRes.data.length">
            Aucune réservation récente
          </div>
          <div *ngFor="let res of lastRes.data" class="res">
            <div class="res-top">
              <strong>{{ res.imb }}</strong>
              <i
                >{{ res.appointment_day.date | date : "dd/MM/yyyy" }}
                {{
                  res.appointment_day.type == "1" ? "matin" : "après midi"
                }}</i
              >
            </div>
            <div *ngIf="res.fullAddress.length">{{ res.fullAddress}}</div>
            <div>
              {{ res.firstname }} {{ res.lastname }} - {{ res.phone_number }} - {{res.email}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent8'"
  ></app-loader>
</div>

<div
  class="modal-racco-wrapper"
  *ngIf="dayInfos"
  [class.show]="dayInfos && dayInfos.length"
>
  <div class="modal-racco">
    <ng-icon
      name="ionCloseOutline"
      class="close"
      (click)="closeModal()"
    ></ng-icon>
    <div class="date">Rendez-vous du {{ dayInfos[0].date }}</div>
    <div class="plage">
      Matin
      <span class="rdv">{{ dayInfos[0].use }} rendez-vous</span>
      <span class="dispo"
        >{{ dayInfos[0].available }} dispo{{
          dayInfos[0].available > 1 ? "s" : ""
        }}</span
      >
    </div>

    <div class="res-wrapper in-modal">
      <div class="res" *ngFor="let rdv of dayInfos[0].appointments">
        <div><span>IMB:</span> {{ rdv.imb }}</div>
        <div><span>Nom:</span> {{ rdv.firstname }} {{ rdv.lastname }}</div>
        <div><span>Email:</span> {{ rdv.email }}</div>
        <div *ngIf="rdv.fullAddress">
          <span>Adresse:</span> {{ rdv.fullAddress }}
        </div>
        <div><span>Téléphone:</span> {{ rdv.phone_number }}</div>
        <div><span>Propiétaire:</span> {{ rdv.owner ? "Oui" : "Non" }}</div>
        <div *ngIf="rdv.more_infos && rdv.more_infos.length">
          <span>Commentaire:</span> {{ rdv.more_infos }}
        </div>
      </div>
    </div>
    <div class="plage">
      Après-midi
      <span class="rdv">{{ dayInfos[1].use }} rendez-vous</span>
      <span class="dispo"
        >{{ dayInfos[1].available }} dispo{{
          dayInfos[1].available > 1 ? "s" : ""
        }}</span
      >
    </div>
    <div class="res-wrapper in-modal">
      <div class="res" *ngFor="let rdv of dayInfos[1].appointments">
        <div><span>IMB:</span> {{ rdv.imb }}</div>
        <div><span>Nom:</span> {{ rdv.firstname }} {{ rdv.lastname }}</div>
        <div><span>Email:</span> {{ rdv.email }}</div>
        <div *ngIf="rdv.fullAddress">
          <span>Adresse:</span> {{ rdv.fullAddress }}
        </div>
        <div><span>Téléphone:</span> {{ rdv.phone_number }}</div>
        <div><span>Propiétaire:</span> {{ rdv.owner ? "Oui" : "Non" }}</div>
        <div *ngIf="rdv.more_infos && rdv.more_infos.length">
          <span>Commentaire:</span> {{ rdv.more_infos }}
        </div>
      </div>
    </div>
  </div>
</div>
