import { Component, ElementRef, Input, ViewChild, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {


  @Input() class!: string;
  @Input() originalTableau: any;
  tableau: any;
  layout: any[] = [];
  smartLoader: any = false;

  @ViewChild('fake') fake!: ElementRef;
  @ViewChild('overlay') overlay!: ElementRef;

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    if (!this.originalTableau){
      this.smartLoader = false;
      return
    } else {
      this.smartLoader = true;
    };

    this.class = this.class.split(' ')[1];
    this.originalTableau = this.originalTableau.toArray();
    if (!this.originalTableau[0]) {
      this.smartLoader = false;
      return;
    }
    this.originalTableau = this.originalTableau[0].nativeElement;

    this.originalTableau.childNodes.forEach((child: any) => {
      if (child.nodeType !== 1) return;
      this.layout.push(child.className);
    });
  }

  ngAfterViewInit() {
    // for each div in this.overlay, add a slight delay to the animation
    if (!this.smartLoader) return;
    // add the .penetration.fake element to this.overlay
    let i = 0;
    if (this.fake) {
      this.fake.nativeElement.style.animationDelay = '0s';
      i++;
    }
    // this.overlay.nativeElement.prepend(this.fake.nativeElement);
    this.overlay.nativeElement.childNodes.forEach((child: any, i: number) => {
      let delay = i * 0.1;
      if (!child.style) return;
      child.style.animationDelay = `${delay}s`;
    });
  }
}
