<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="date" *ngIf="allData && allData.ending_date">
      Lot {{ filtres.lot }} prévue le
      {{ allData.ending_date | date : "longDate" }}
    </div>
    <div class="penetration" *ngIf="allData">
      <span class="num">{{ allData.penetration }}%</span
      ><span class="text">Taux de pénétration</span>
    </div>
    <div class="parent3" *ngIf="allData" #tableauRef [ngClass]="{'rosace': platform === 'rosace'}">
      <div class="div1" [id]="allData.imb_ipe_deployes.key">
        <span class="num">{{ allData.imb_ipe_deployes.rows }}</span
        ><span class="text">IMB Déployé</span>
        <span class="source">Source: IPE</span>
      </div>

      <div class="div3 inactif" [id]="allData.imb_cibles.key">
        <span class="num">{{ allData.imb_cibles.rows }}</span
        ><span class="text">IMB Cible</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div4 motifs">
        <span class="source_motif" *ngIf="allData.blocages_imb_cibles.length">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imb_cibles"
          (click)="openModal(allData.blocages_imb_cibles.key, allData, item.motif_bloc)"
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div5 inactif" [id]="allData.imb_en_cours.key">
        <span class="num">{{ allData.imb_en_cours.rows }}</span
        ><span class="text">IMB En cours de déploiement</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div6 motifs">
        <span class="source_motif" *ngIf="allData.blocages_imbs_encours.length">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imbs_encours"
          (click)="
            openModal(allData.blocages_imbs_encours.key, allData, item.motif_bloc)
          "
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div7 inactif" [id]="allData.imb_rad.key">
        <span class="num">{{ allData.imb_rad.rows }}</span
        ><span class="text">IMB - RAD</span>
        <span class="source">Source: {{platform == 'rosace' ? 'STBAN' : 'Annexe'}}</span>
      </div>
      <div class="div8" *ngIf="allData.go_region" [id]="allData.go_region.key">
        <span class="num">{{ allData.go_region.rows }}</span
        ><span class="text">GO REGION</span
        ><span class="text green">{{ allData.go_region.data }} € en cours</span>
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div9"
        *ngIf="allData.nogo_region"
        [id]="allData.nogo_region.key"
      >
        <span class="num">{{ allData.nogo_region.rows }}</span
        ><span class="text">NOGO REGION</span
        ><span class="text red">{{ allData.nogo_region.data }} € refusé</span>
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div10"
        *ngIf="allData.en_attente"
        [id]="allData.en_attente.key"
      >
        <span class="num">{{ allData.en_attente.rows }}</span
        ><span class="text">EN ATTENTE</span
        ><span class="text red"
          >{{ allData.en_attente.data }} € en attente</span
        >
        <span class="source">Source: IPE</span>
      </div>
      <div class="div11" *ngIf="allData.prequal" [id]="allData.prequal.key">
        <span class="num">{{ allData.prequal.rows }}</span
        ><span class="text">PREQUAL</span
        ><span class="text red">{{ allData.prequal.data }} € non demandé</span>
        <span class="source">Source: IPE</span>
      </div>
      <div
        class="div12"
        *ngIf="allData.presence_cuivre"
        [id]="allData.presence_cuivre.key"
      >
        <span class="num">{{ allData.presence_cuivre.rows }}</span
        ><span class="text">Total présence cuivre</span
        ><span class="text red"
          >{{ allData.presence_cuivre.data }} € non demandé</span
        >
        <span class="source">Source: Orange</span>
      </div>

      <div class="div13 actif" [id]="allData.pto_actif.key">
        <span class="num">{{ allData.pto_actif.data }}</span
        ><span class="text">PTO Actif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div14 inactif" [id]="allData.pto_inactif.key">
        <span class="num">{{ allData.pto_inactif.data }}</span
        ><span class="text">PTO Inactif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div
        class="div21 sans-pto echec"
        [id]="echecs.nb_commandes_echec_pto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_commandes_echec_pto.data }}</span
        ><span class="text">Échecs avec PTO</span>
      </div>
      <div
        class="div22 actif echec"
        [id]="echecs.nb_commandes_encours_pto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_commandes_encours_pto.data }}</span
        ><span class="text">commandes en cours avec PTO</span>
      </div>
      <div
        class="div23 inactif echec"
        [id]="echecs.nb_annulation_pto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_annulation_pto.data }}</span
        ><span class="text">commandes en annulé avec PTO</span>
      </div>

      <div class="div15 sans-pto" [id]="allData.sans_pto.key">
        <span class="num">{{ allData.sans_pto.data }}</span
        ><span class="text">Sans PTO</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div
        class="div16 sans-pto echec"
        [id]="echecs.nb_commandes_echec_sanspto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_commandes_echec_sanspto.data }}</span
        ><span class="text">Échecs sans PTO</span>
      </div>
      <div
        class="div19 actif echec"
        [id]="echecs.nb_commandes_encours_sanspto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_commandes_encours_sanspto.data }}</span
        ><span class="text">commandes en cours sans PTO</span>
      </div>
      <div
        class="div20 inactif echec"
        [id]="echecs.nb_annulation_sanspto.key"
        *ngIf="echecs"
      >
        <span class="num">{{ echecs.nb_annulation_sanspto.data }}</span
        ><span class="text">commandes en annulé sans PTO</span>
      </div>

      <div class="div17" [id]="allData.gels_commerciaux.key">
        <span class="num">{{ allData.gels_commerciaux.rows }}</span
        ><span class="text">Gel commerciaux</span>
        <span class="source">Source: IPE</span>
      </div>
      <div class="div18" [id]="allData.imb_ecart_acces.key">
        <span class="num">{{ allData.imb_ecart_acces.rows }} IMB</span
        ><span class="text"> En écart d'accès</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
        <ng-icon
          class="map"
          name="ionLocationSharp"
          (click)="openMapEcarts($event)"
        ></ng-icon>
      </div>
    </div>
    <!-- <app-loader *ngIf="loading" class="host" [class]="'parent3'"></app-loader> -->
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent3'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
