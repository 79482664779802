<div class="wrapper-all">
  <div class="content" *ngIf="!loading && allData">
    <div class="commune">
      {{ selectedCity.name }} / Lot {{ selectedCity.lot }}
    </div>
    <div class="informations">
      <div class="vdr">
        <span class="title">Informations VDR</span>
        <div class="stats">
          <div>
            <span class="num">{{ allData.vdr_total }}</span
            ><span class="text">Dossiers</span>
          </div>
          <div>
            <span class="num">{{ allData.vdr_ouvert.length }}</span
            ><span class="text">Ouverts</span>
          </div>
          <div>
            <span class="num">{{ allData.vdr_termine.length }}</span
            ><span class="text">Terminés</span>
          </div>
        </div>
      </div>
      <div class="referencement">
        <span class="title">Informations Référencement</span>
        <div class="stats">
          <div>
            <span class="num">{{ allData.ref_total }}</span
            ><span class="text">Dossiers</span>
          </div>
          <div>
            <span class="num">{{ allData.ref_ouvert.length }}</span
            ><span class="text">Ouverts</span>
          </div>
          <div>
            <span class="num">{{
              allData.ref_total - allData.ref_ouvert.length
            }}</span
            ><span class="text">Terminés</span>
          </div>
        </div>
      </div>
    </div>
    <div class="parent6" #tableauRef>
      <div class="div1 inactif" [id]="allData.gels_commerciaux.key">
        <span class="num">{{ allData.gels_commerciaux.rows }}</span
        ><span class="text">Gel Com</span>
      </div>
      <div class="div2 motifs"></div>

      <div class="div3 inactif" [id]="allData.imb_cibles.key">
        <span class="num">{{ allData.imb_cibles.rows }}</span
        ><span class="text">IMB Cible</span>
      </div>
      <div class="div4 motifs">
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imb_cibles"
          (click)="openModal(allData.blocages_imb_cibles.key, item.motif_bloc)"
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div5 inactif" [id]="allData.imb_en_cours.key">
        <span class="num">{{ allData.imb_en_cours.rows }}</span
        ><span class="text">IMB En cours de déploiement</span>
      </div>
      <div class="div6 motifs">
        <div
          class="motif"
          *ngFor="let item of allData.blocages_imbs_encours"
          (click)="
            openModal(allData.blocages_imbs_encours.key, item.motif_bloc)
          "
        >
          <span class="num">{{ item.total }}</span
          ><span class="text">{{ item.motif_bloc }}</span>
        </div>
      </div>

      <div class="div7 inactif" [id]="allData.imb_rad.key">
        <span class="num">{{ allData.imb_rad.rows }}</span
        ><span class="text">IMB - RAD</span>
      </div>
      <div
        class="div8"
        *ngIf="allData.presence_cuivre"
        [id]="allData.presence_cuivre.key"
      >
        <span class="num">{{ allData.presence_cuivre.rows }}</span
        ><span class="text">Total présence cuivre</span>
      </div>

      <div class="div9" [id]="allData.imb_projetfutur.key">
        <span class="num">{{ allData.imb_projetfutur.rows }}</span
        ><span class="text">Projet Futur</span>
      </div>
      <div class="div10" [id]="allData.dlpi_avant_2025.key">
        <span class="num">{{ allData.dlpi_avant_2025.rows }}</span
        ><span class="text">DLPI < 2025</span>
      </div>
      <div class="div11" [id]="allData.dlpi_apres_2025.key">
        <span class="num">{{ allData.dlpi_apres_2025.rows }}</span
        ><span class="text">DLPI > 2025</span>
      </div>

      <div class="div12 bleu">
        <ng-container *ngIf="allData.gfus && allData.gfus.details.length">
          <span class="num">GFU</span>
          <span
            class="text"
            *ngIf="allData.gfus.details[0].date_saisie_commande"
            >Commande passée le
            {{
              allData.gfus.details[0].date_saisie_commande | date : "dd/MM/yyyy"
            }}</span
          >
          <span
            class="text"
            *ngIf="allData.gfus.details[0].date_mise_en_service"
            >Mise en service le
            {{
              allData.gfus.details[0].date_mise_en_service | date : "dd/MM/yyyy"
            }}</span
          >
          <span
            class="text"
            *ngIf="
              allData.gfus.details[0].date_previsionnelle &&
              !allData.gfus.details[0].date_mise_en_service
            "
            >Date prévisionnelle le
            {{
              allData.gfus.details[0].date_previsionnelle | date : "dd/MM/yyyy"
            }}</span
          >
          <div class="links">
            <div
              class="link"
              *ngFor="let lien of allData.gfus.details"
              (click)="openMap(lien)"
            >
              <div class="left">
                <span class="text">Lien: {{ lien.cls }}</span>
                <span class="text" *ngIf="lien.address"
                  >Adresse: {{ lien.address }}</span
                >
              </div>
              <ng-icon name="ionPin"></ng-icon>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            !allData.gfus || (allData.gfus && !allData.gfus.details.length)
          "
        >
          <span class="num">GFU</span>
          <span class="text">Pas de GFU</span>
        </ng-container>
      </div>
      <div class="div13" [id]="allData.imb_ipe_deployes.key">
        <span class="num">{{ allData.imb_ipe_deployes.rows }}</span
        ><span class="text">IMB Déployés</span>
      </div>
      <div class="div14 actif" [id]="allData.pto_actif.key">
        <span class="num">{{ allData.pto_actif.data }}</span
        ><span class="text">PTO Actif</span>
      </div>
      <div class="div15 inactif" [id]="allData.pto_inactif.key">
        <span class="num">{{ allData.pto_inactif.data }}</span
        ><span class="text">PTO Inactif</span>
      </div>
      <div class="div16 sans-pto" [id]="allData.sans_pto.key">
        <span class="num">{{ allData.sans_pto.data }}</span
        ><span class="text">Sans PTO</span>
      </div>
      <div class="div17 sans-pto" [id]="allData.nb_commandes_echec_pto.key">
        <span class="num">{{ allData.nb_commandes_echec_pto.data }}</span
        ><span class="text">Échecs avec PTO</span>
      </div>
      <div class="div18 sans-pto" [id]="allData.nb_commandes_echec_sanspto.key">
        <span class="num">{{ allData.nb_commandes_echec_sanspto.data }}</span
        ><span class="text">Échecs sans PTO</span>
      </div>
      <div class="div19 actif" [id]="allData.nb_commandes_encours_pto.key">
        <span class="num">{{ allData.nb_commandes_encours_pto.data }}</span
        ><span class="text">commandes en cours avec PTO</span>
      </div>
      <div class="div20 actif" [id]="allData.nb_commandes_encours_sanspto.key">
        <span class="num">{{ allData.nb_commandes_encours_sanspto.data }}</span
        ><span class="text">commandes en cours sans PTO</span>
      </div>
      <div class="div21 inactif" [id]="allData.nb_annulation_pto.key">
        <span class="num">{{ allData.nb_annulation_pto.data }}</span
        ><span class="text">commandes en annulé avec PTO</span>
      </div>
      <div class="div22 inactif" [id]="allData.nb_annulation_sanspto.key">
        <span class="num">{{ allData.nb_annulation_sanspto.data }}</span
        ><span class="text">commandes en annulé sans PTO</span>
      </div>
    </div>

    <div class="map-frame" [class.active]="allData.gfus && allData.gfus.details.length">
      <div id="map"></div>
    </div>
  </div>
  <div class="content" *ngIf="!loading && !allData">
    <span class="no-commune">Sélectionnez une commune</span>
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent6'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
