import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { LoaderComponent } from '../components/loader/loader.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';
import { ModalVueInfosComponent } from '../components/modal-vue-infos/modal-vue-infos.component';

import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    NgIconsModule,
    LoaderComponent,
    NgxDatatableModule,
    CommonModule,
    ModalVueInfosComponent,
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  @Input() filtres: any;
  @Output() onHide = new EventEmitter<boolean>();

  loading: boolean = true;
  allData: any;
  isModalInfosOpen: boolean = false;
  dossiers: any;
  allIMBInfos: any;
  rowData: any;

  // create table columns
  columns = [
    { name: 'VDR', sortable: false },
    { name: 'Lot', prop: 'lot', sortable: false },
    // { name: 'Catégorie', prop: 'categorie' },
    { name: 'IMB', prop: 'IdentifiantImmeuble' },
    { name: 'Insee', prop: 'CodeInseeImmeuble' },
    { name: 'Commune', prop: 'CommuneImmeuble' },
    { name: 'Type voie.', prop: 'TypeVoieImmeuble' },
    { name: 'Voie.', prop: 'NomVoieImmeuble' },
    { name: 'n°  ', prop: 'NumeroVoieImmeuble' },
    // { name: 'compl.', prop: 'motif' },
    // { name: 'Prises.', prop: 'nb_prises' },
    { name: 'Prises', prop: 'NombrelogementsAdresseIPE' },
  ];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.loading = true;
    if (this.filtres.ref === '') {
      return;
    }

    if (
      this.filtres.nb_items_total &&
      this.filtres.nb_items_prises &&
      this.filtres.nb_items_total !== this.filtres.nb_items_prises
    ) {
      this.filtres.nb_items_prises = this.filtres.nb_items_prises
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      this.filtres.total =
        ' Lignes / ' + this.filtres.nb_items_prises + ' Prises';
    } else {
      this.filtres.total = ' Lignes';
    }

    if (this.apiService.filtres.onglet === '5') {
      this.apiService.getDataV2(this.filtres).subscribe((res: any) => {
        this.loading = false;
        this.allData = res;
        if (this.filtres.ref === 'horscategorie') {
          this.makeCatChanges();
        } else if (this.allData[0] && this.allData[0].ref_imb) {
          this.makeCibleEncoursChanges();
        } else {
          this.makeIPEChanges();
        }

        if (this.filtres.ref === 'imb_ecart_acces') {
          this.getAllIMBInfos();
        }
      });
    } else {
      this.apiService.getData(this.filtres).subscribe((res: any) => {
        this.loading = false;
        this.allData = res;
        if (
          this.filtres.ref === 'imb_cat_3' ||
          this.filtres.ref === 'imb_cat_4'
        ) {
          this.makeCatChanges();
        } else if (this.allData[0] && this.allData[0].ref_imb) {
          this.makeCibleEncoursChanges();
        } else {
          this.makeIPEChanges();
        }

        if (this.filtres.ref === 'imb_ecart_acces') {
          this.getAllIMBInfos();
        }
      });
    }
  }

  makeCatChanges() {
    this.columns = [
      { name: 'VDR', prop: 'vdr', sortable: false },
      { name: 'Lot', prop: 'lot', sortable: false },
      { name: 'Catégorie', prop: 'categorie' },
      { name: 'Insee', prop: 'insee' },
      { name: 'Commune', prop: 'commune' },
      { name: 'Voie.', prop: 'voie' },
      { name: 'n°  ', prop: 'numero' },
      { name: 'Prises', prop: 'nb_prises' },
    ];

    let allIMBS = this.allData.map((element: any) => element.imb);
    allIMBS = allIMBS.join(',');
    this.getAllDossiers(allIMBS);
  }

  makeIPEChanges() {
    this.allData.forEach((element: any) => {
      element.NumeroVoieImmeuble =
        element.NumeroVoieImmeuble + element.ComplementNumeroVoieImmeuble;
      element.imb = element.IdentifiantImmeuble;
      if (element.lot) {
        element.lot = element.lot;
      } else {
        element.lot = this.filtres.lot;
      }
    });

    let allIMBS = this.allData.map((element: any) => element.imb);
    // transform allIMBS to a string seperated by commas
    allIMBS = allIMBS.join(',');
    this.getAllDossiers(allIMBS);
  }

  makeCibleEncoursChanges() {
    this.columns = [
      { name: 'VDR', prop: 'vdr', sortable: false },
      { name: 'Lot', prop: 'lot', sortable: false },
      { name: 'Catégorie', prop: 'cat_reco' },
      { name: 'IMB', prop: 'ref_imb' },
      { name: 'Insee', prop: 'c_insee' },
      { name: 'Commune', prop: 'lib_com' },
      { name: 'Type voie.', prop: 'typevoie' },
      { name: 'Voie.', prop: 'voie_adr' },
      { name: 'n°  ', prop: 'num_adr' },
      { name: 'Prises', prop: 'nb_prise' },
    ];

    this.allData.forEach((element: any, index: number) => {
      element.num_adr = element.num_adr + element.ex_num_adr;
      element.imb = element.ref_imb;
    });
    let allIMBS = this.allData.map((element: any) => element.imb);
    allIMBS = allIMBS.join(',');
    this.getAllDossiers(allIMBS);
  }

  getAllDossiers(allIMBS: string) {
    if (allIMBS === '') return;
    this.apiService.getVDR(allIMBS).subscribe((res: any) => {
      if (res.error) {
        alert(res.error);
      } else {
        this.dossiers = res;

        this.allData.forEach((element: any, index: number) => {
          let nbrClotures = 0;
          let nbrOuverts = 0;
          // find in this.dossiers the element with the same imb as the current element
          this.dossiers.forEach((dossier: any) => {
            if (element.imb === dossier.imb) {
              if (dossier.si_statut_code >= 100) {
                nbrClotures++;
              } else {
                nbrOuverts++;
              }
            }
          });

          element.vdr =
            '<span class="green">' +
            nbrClotures +
            '</span> <span class="red">' +
            nbrOuverts +
            '</span>';
        });
      }
    });
  }

  getAllIMBInfos() {
    let allIMBS = this.allData.map((element: any) => element.imb);
    allIMBS = allIMBS.join(',');
    this.apiService.getIMBInfo(allIMBS).subscribe((res: any) => {
      if (res.error) {
        alert(res.error);
      } else {
        this.allIMBInfos = res;
        this.allData.forEach((element: any, index: number) => {
          this.allIMBInfos.forEach((imbInfo: any) => {
            if (element.imb === imbInfo.imb) {
              if (imbInfo.difference_prise > 0) {
                element.NombrelogementsAdresseIPE =
                  imbInfo.nb_prise + ' (' + imbInfo.difference_prise + ')';
              } else if (imbInfo.difference_prise < 0) {
                element.NombrelogementsAdresseIPE =
                  imbInfo.nb_prise +
                  ' <span style="color: red;">(' +
                  imbInfo.difference_prise +
                  ')</span>';
              }
            }
          });
        });
      }
    });
  }

  setPage(event: any) {
    this.filtres.page = event.offset;
    this.fetchData();
  }

  closeModal() {
    this.onHide.emit(true);
  }

  openModalInfos(event: any) {
    if (event.type === 'click') {
      this.isModalInfosOpen = false;
      this.apiService.getVDR(event.row.imb).subscribe((res: any) => {
        if (res.error) {
          alert(res.error);
        } else {
          this.dossiers = res;
          this.rowData = event.row;
          this.isModalInfosOpen = true;
        }
      });
    }
  }

  closeModalInfos() {
    this.isModalInfosOpen = false;
  }


  isDownloading = false;
  downloadData() {
    let filtreCopy = JSON.parse(JSON.stringify(this.filtres));
    console.log(filtreCopy);
    filtreCopy.page = null;
    filtreCopy.nb_per_page = null;
    filtreCopy.csv = 1;
    this.isDownloading = true;

    if (this.apiService.filtres.onglet === '5') {
      this.apiService.getDataCSV2(filtreCopy).subscribe((res: any) => {
        let blob = new Blob([res], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'export.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.isDownloading = false;
      });
    } else {
      this.apiService.getDataCSV(filtreCopy).subscribe((res: any) => {
        let blob = new Blob([res], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'export.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.isDownloading = false;
      });
    }
  }
}
