import { ChangeDetectorRef, Component, Inject, ViewChildren } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-rad',
  standalone: true,
  imports: [LoaderComponent, CommonModule, ModalComponent],
  templateUrl: './rad.component.html',
  styleUrl: './rad.component.scss'
})
export class RadComponent {
  loading: boolean = true;
  allData: any;
  filtres: any;
  filtresData: any;
  isModalOpen: boolean = false;
  lines: any = [];
  platform: any = '';
  cities: any;
  
  @ViewChildren('tableauRef') tableauRef: any = '';

  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  componentDestroyed$: Subject<boolean> = new Subject()

  ngOnInit() {
    this.filtres = this.apiService.filtres;
    if (this.apiService.cities) {
      this.cities = this.apiService.cities;
    } else {
      this.apiService.citiesChange.subscribe((res) => {
        this.cities = res;
      });
    }

    if (this.apiService.filtres.lot !== null) {
      this.fetchData();
    }

    this.apiService.filtresChange.pipe(takeUntil(this.componentDestroyed$)).subscribe((filtres: any) => {
      this.filtres = filtres;
      if (this.filtres.onglet !== '3') return;
      this.loading = true;
      this.fetchData();
    });

    this.platform = localStorage.getItem('platform');
    if (this.platform == 'rosace') {
      this.platform = 'ROSACE';
    } else if (this.platform == 'losange') {
      this.platform = 'LOSANGE';
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
  }

  ngAfterViewInit() {
    this.tableauRef.changes.subscribe(() => {
      if (!this.tableauRef.first) return;
      let tableau = this.tableauRef.first.nativeElement;
      tableau.childNodes.forEach((child: any) => {
        child.addEventListener('click', () => {
          this.openModal(
            child.id,
            null,
            child.querySelector('.text')?.textContent
          );
        });
      });
    });

    this.cd.detectChanges();
  }

  openModal(key: any, motif?: any, text?: any) {
    this.isModalOpen = true;
    let nb_items_total;
    let nb_items_prises;

    if (motif) {
      //iterate through allData[key] and find the item with the motif property equal to motif
      this.allData[key].forEach((item: any) => {
        if (item.motif_bloc === motif) nb_items_total = item.total;
      });
    } else {
      nb_items_total = this.allData[key].rows;
      nb_items_prises = this.allData[key].data;
    }
    this.filtresData = {
      ref: key,
      lot: this.filtres.lot,
      insee_city: this.filtres.insee_city,
      insee_epci: this.filtres.insee_epci,
      nb_per_page: 10,
      page: 0,
      blocage: motif,
      nb_items_total: nb_items_total,
      nb_items_prises: nb_items_prises,
      text: text,
    };
  }

  closeModal(event: any) {
    this.isModalOpen = false;
  }

  fetchData() {
    // if (this.lines.length) {
    //   this.lines.forEach((line: any) => line.remove());
    // }
    // this.lines = [];

    this.apiService.getNumbers(this.filtres).subscribe((res: any) => {
      if (res.success) {
        this.allData = res.data;
        for (let key in this.allData) {
          if (
            typeof this.allData[key] === 'object' &&
            this.allData[key] !== null
          ) {
            this.allData[key].key = key;
          }
        }
        this.loading = false;
        // this.drawLines();
      }
    });
  }
}
