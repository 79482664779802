<div class="wrapper-all">
  <div class="content" *ngIf="!loading">
    <div class="parent1" *ngIf="allData" #tableauRef>
      <div class="div1" [id]="allData.imb_ipe.key">
        <span class="num">{{ allData.imb_ipe.rows }}</span
        ><span class="text">Total IMB IPE "{{ platform }}"</span>
          <span class="text">({{allData.imb_ipe.data}} prises)</span>
      </div>
      <div class="div2" [id]="allData.adresses_orange.key">
        <span class="num">{{ allData.adresses_orange.data }}</span
        ><span class="text">Adresses ORANGE</span>
      </div>
      <div class="div3" [id]="allData.acces_cuivre_orange.key">
        <span class="num">{{ allData.acces_cuivre_orange.data }}</span
        ><span class="text">Accès cuivre ORANGE</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div4">
        <span class="num">{{ allData.adresses_orange.data }}</span
        ><span class="text">Catégorie ORANGE</span>
      </div>
      <div class="div5" [id]="allData.imb_cat_1.key">
        <span class="num">{{ allData.imb_cat_1.data }}</span
        ><span class="text"
          >IMB dédoublonnés <br />
          Catégorie 1</span
        >
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div6" [id]="allData.imb_cat_2.key">
        <span class="num">{{ allData.imb_cat_2.data }}</span
        ><span class="text"
          >IMB dédoublonnés <br />
          Catégorie 2</span
        ><ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div7" [id]="allData.imb_cat_3.key">
        <span class="num">{{ allData.imb_cat_3.data }}</span
        ><span class="text">IMB Catégorie 3</span
        ><ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
        <ng-icon
          class="map"
          name="ionLocationSharp"
          (click)="openMapCat($event)"
        ></ng-icon>
      </div>
      <div class="div8" [id]="allData.imb_cat_4.key">
        <span class="num">{{ allData.imb_cat_4.data }}</span
        ><span class="text">IMB Catégorie 4</span
        ><ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
        <ng-icon
          class="map"
          name="ionLocationSharp"
          (click)="openMapCat($event)"
        ></ng-icon>
      </div>

      <div class="div10" [id]="allData.prise_cat_1.key">
        <span class="num">{{ allData.prise_cat_1.data }}</span
        ><span class="text">Prises</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div11" [id]="allData.prise_cat_2.key">
        <span class="num">{{ allData.prise_cat_2.data }}</span
        ><span class="text">Prises</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div14" [id]="allData.imb_ecart_acces.key">
        <span class="num">{{ allData.imb_ecart_acces.data }} IMB</span
        ><span class="text"> En écart d'accès</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
        <ng-icon
          class="map"
          name="ionLocationSharp"
          (click)="openMapEcarts($event)"
        ></ng-icon>
      </div>

      <div class="div15"><span class="text">Total PTO</span></div>
      <div class="div16 actif" [id]="allData.actif_cat_1.key">
        <span class="num">{{ allData.actif_cat_1.data }}</span
        ><span class="text">Actif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div19 actif" [id]="allData.actif_cat_2.key">
        <span class="num">{{ allData.actif_cat_2.data }}</span
        ><span class="text">Actif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>

      <div class="div33 actif" [id]="allData.prises_actives_hors_categorie.key">
        <span class="num">{{ allData.prises_actives_hors_categorie.data }}</span
        ><span class="text">Actif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div17 inactif" [id]="allData.inactif_cat_1.key">
        <span class="num" style="font-style: italic">{{allData.inactif_cat_1.data}}</span
        ><span class="text">Inactif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div20 inactif" [id]="allData.inactif_cat_2.key">
        <span class="num" style="font-style: italic">{{allData.inactif_cat_2.data}}</span
        ><span class="text">Inactif</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>

      <div class="div18 sans-pto" [id]="allData.sans_pto_cat_1.key">
        <span class="num">{{ allData.sans_pto_cat_1.data }}</span
        ><span class="text">Sans PTO</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>
      <div class="div21 sans-pto" [id]="allData.sans_pto_cat_2.key">
        <span class="num">{{ allData.sans_pto_cat_2.data }}</span
        ><span class="text">Sans PTO</span>
        <ng-icon class="more" name="ionEllipsisHorizontal"></ng-icon>
      </div>

      <div class="div34" [id]="allData.prises_hors_categorie.key">
        <span class="num">{{ allData.prises_hors_categorie.rows }}</span
        ><span class="text">({{ allData.prises_hors_categorie.data }} prises)</span>
        <span class="text">IMB Hors Catégorie</span>
      </div>
      <div class="div32 inactif">
        <!-- <span class="num">{{allData.prises_inactives_hors_categorie.data}}</span> -->
        <span class="num" style="font-style: italic">{{allData.prises_inactives_hors_categorie.data}}</span>
        <span class="text">Inactif</span>
      </div>
      <div class="div31 sans-pto" [id]="allData.sans_pto_hors_categorie.key">
        <span class="num">{{ allData.sans_pto_hors_categorie.data }}</span
        ><span class="text">Sans PTO</span>
      </div>

      <div class="div28 hidden"></div>
      <div class="div29 hidden"></div>
      <div class="div30 hidden"></div>
      <div class="div9 hidden"></div>
      <div class="div35 hidden"></div>
      <div class="div36 hidden"></div>
      <div class="div22 hidden"></div>
      <div class="div23 hidden"></div>
      <div class="div24 hidden"></div>
      <div class="div25 hidden"></div>
      <div class="div26 hidden"></div>
      <div class="div27 hidden"></div>
      <div class="div12 hidden"></div>
      <div class="div13 hidden"></div>
    </div>
    <!-- <app-loader *ngIf="loading" [class]="'parent1'"></app-loader> -->
  </div>
  <app-loader
    *ngIf="loading"
    [originalTableau]="tableauRef"
    class="host"
    [class]="'parent1'"
  ></app-loader>
  <app-modal
    *ngIf="isModalOpen"
    [filtres]="filtresData"
    (onHide)="closeModal($event)"
    class="modal"
  ></app-modal>
</div>
