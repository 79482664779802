import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { ionFileTray, ionLocationSharp, ionCloseOutline, ionMenuOutline, ionCaretDownOutline, ionCaretUpOutline, ionEllipsisHorizontalOutline, ionEllipsisHorizontal, ionLogOutOutline, ionDownloadOutline, ionMapOutline, ionImageOutline, ionMap, ionImage, ionPin } from '@ng-icons/ionicons';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideNgIconsConfig({
      size: '1.2em',
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    importProvidersFrom(HttpClientModule),
    provideIcons({ionLocationSharp, ionFileTray, ionCloseOutline, ionMenuOutline, ionCaretDownOutline, ionCaretUpOutline, ionEllipsisHorizontalOutline, ionEllipsisHorizontal, ionLogOutOutline, ionDownloadOutline, ionMapOutline, ionImageOutline, ionMap, ionImage, ionPin}),
    provideRouter(routes),
    provideHttpClient(),
    {provide: LOCALE_ID, useValue: 'fr-FR' }
  ]
};
